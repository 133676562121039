/* eslint-disable no-else-return */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import toast from 'react-hot-toast';

import { create } from 'zustand';

export const useDashboard = create((set, get) => ({
  dashboardState: {
    allDashboadData: [],
    getMembertableData: [],
    getMembersStatusData: {},
    getAgeData: [],
    getGenderData: [],
    getConsultationsData: {},
    getInsightsData: [],
    getMemberData: [],
    date: {
      start_date: '',
      end_date: '',
    },
  },
  // download_url: '',
  loading: null,
  memberLoading: null,
  consultationsLoading: null,
  memberStatusLoading: null,
  leaderBoardLoading: null,
  error: null,
  getAllDasboardData: async () => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['dashboard'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/dashboard`,
            {
              id: tokenData?.organization_id ?? '',
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      return set((state) => ({
        loading: false,
        dashboardState: {
          ...state.dashboardState,
          allDashboadData: data,
          getMembertableData: data?.members,
          getMembersStatusData: data?.MemberStatus,
          getAgeData: data?.age,
          getConsultationsData: data?.consultations,
          getInsightsData: data?.insights,
          getGenderData: data?.gender,
        },
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Sometrhing went wrong please try again!',
      );
    }
  },

  getMembersDownload: async () => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      // const { dashboardState } = get();
      set({ memberLoading: true });

      const data = await queryClient.fetchQuery({
        queryKey: ['all-user-download'],
        queryFn: async () => {
          const data = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/all-user-download`,
            {
              id: tokenData?.organization_id ?? '',
            },
            true,
          );
          return data;
        },
        staleTime: 5000,
      });

      if (data?.data?.statusCode === 200) {
        set({
          memberLoading: false,
        });
        return data?.data?.data?.url ?? '';
      } else {
        set({
          memberLoading: false,
        });
        toast.error('some thing went wrong plesae try again!....');
        return '';
      }
    } catch (error) {
      set({ memberLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },
  getConsultationsDownload: async (from_date, to_date) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ consultationsLoading: true });

      const data = await queryClient.fetchQuery({
        queryKey: ['consultation-download', from_date],
        queryFn: async () => {
          const data = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/consultation-download`,
            {
              id: tokenData?.organization_id ?? '',
              from_date: from_date ?? '',
              to_date: to_date ?? '',
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (data?.data?.statusCode === 200) {
        set({
          consultationsLoading: false,
        });
        return data?.data?.data?.url ?? '';
      } else {
        set({
          consultationsLoading: false,
        });
        toast.error('some thing went wrong plesae try again!....');
        return '';
      }
    } catch (error) {
      set({ consultationsLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },
  getMemberStatusDownload: async (from_date, to_date) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ memberStatusLoading: true });

      const data = await queryClient.fetchQuery({
        queryKey: ['user-status-download', from_date],
        queryFn: async () => {
          const data = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/user-status-download`,
            {
              id: tokenData?.organization_id ?? '',
              from_date: from_date ?? '',
              to_date: to_date ?? '',
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (data?.data?.statusCode === 200) {
        set({
          memberStatusLoading: false,
        });
        return data?.data?.data?.url ?? '';
      } else {
        set({
          memberStatusLoading: false,
        });
        toast.error('some thing went wrong plesae try again!....');
        return '';
      }
    } catch (error) {
      set({ memberStatusLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },
  getLeaderBoardDownload: async (start_date, end_date) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ leaderBoardLoading: true });

      const data = await queryClient.fetchQuery({
        queryKey: ['get_leaderboard_download', end_date],
        queryFn: async () => {
          const data = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/get-leaderboard-download`,
            {
              organization_id: tokenData?.organization_id ?? '',
              start_date: start_date ?? '',
              end_date: end_date ?? '',
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (data?.data?.statusCode === 200) {
        set({
          leaderBoardLoading: false,
        });

        return data?.data?.data?.url ?? '';
      } else {
        set({
          leaderBoardLoading: false,
        });
        toast.error('some thing went wrong plesae try again!....');
        return '';
      }
    } catch (error) {
      set({ leaderBoardLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },
  getConsultationsStatus: async (payload) => {
    try {
      const { dashboardState } = get();
      const { getConsultationsData } = dashboardState;
      // set({ loading: true });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['consultations-status'],
        queryFn: async () => {
          const data = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/consultation/get`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        set({
          dashboardState: {
            ...dashboardState,
            getConsultationsData: {
              ...getConsultationsData,
              completed: data?.data?.consultation?.completed,
              failed: data?.data?.consultation?.failed,
            },
          },
          // loading: false,
        });
        return data;
      } else {
        // set({
        //   loading: false,
        // });
        toast.error('some thing went wrong plesae try again!....');
        return '';
      }
    } catch (error) {
      set({ memberStatusLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },
  getMemberStatus: async (payload) => {
    try {
      // set({ loading: true });
      const { dashboardState } = get();
      const { getMembersStatusData } = dashboardState;
      const { data } = await queryClient.fetchQuery({
        queryKey: ['members-status'],
        queryFn: async () => {
          const data = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/member-status/get`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        set({
          dashboardState: {
            ...dashboardState,
            getMembersStatusData: {
              ...getMembersStatusData,
              MembersActive: data?.data?.member_status?.active,
              MembersInActive: data?.data?.member_status?.in_active,
            },
          },
          // loading: false,
        });
        return data;
      } else {
        set({
          // loading: false,
        });
        toast.error('some thing went wrong plesae try again!....');
        return '';
      }
    } catch (error) {
      set({ memberStatusLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },
  updateDateState: (key, value) => {
    const { dashboardState } = get();
    set({
      dashboardState: {
        ...dashboardState,
        [key]: value,
      },
    });
  },
}));
