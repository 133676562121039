export const Profilecard_Style = {
  paperSx: {
    borderRadius: '8px',
    width: '330px',
    height: '430px',
  },
  avatarSx: {
    border: '30px solid #fff',
    width: '50px',
    height: '50px',
    margin: '-20px 10px 0px 20px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headingSx: {
    fontSize: '12px',
    color: 'text.secondary',
  },
  descSx: {
    fontSize: '14px',
    color: 'text.primary',
    fontWeight: 600,
  },
  cardHeadSx: {
    backgroundColor: 'border.main',
    height: '91px',
  },
};
