import { getDashboardConsultations, getDashboardMemberStatus } from '@hc/dayjs';
import { useDashboard } from '@hc/store';
import { DropBox } from '@hc/ui/atoms';
import { ColumnChart } from '@hc/ui/atoms/charts/columnChart';
import { DonutChart } from '@hc/ui/atoms/charts/donutChart';
import { PieCharts } from '@hc/ui/atoms/charts/pieChart';
import { ChartCard } from '@hc/ui/components/organization/chartCard';
import { queryClient } from '@hc/utils';
import { Box, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { EmptyStateOrg } from '../../emptyState';

export function DashBoradCharts() {
  const [dashboardIndex, setDashBoardIndex] = useState();

  // Store Data
  const {
    dashboardState,
    getAllDasboardData,
    getMemberStatusDownload,
    getConsultationsDownload,
    consultationsLoading,
    memberStatusLoading,
    loading,
  } = useDashboard((state) => ({
    consultationsLoading: state.consultationsLoading,
    memberStatusLoading: state.memberStatusLoading,
    dashboardState: state.dashboardState,
    getAllDasboardData: state.getAllDasboardData,
    getMemberStatusDownload: state.getMemberStatusDownload,
    getConsultationsDownload: state.getConsultationsDownload,
    loading: state.loading,
  }));

  // getConsultationsData
  const donutChart = [
    {
      name: 'Completed',
      value: dashboardState?.getConsultationsData?.completed ?? 0,
      color: '#50C795 ',
    },
    {
      name: 'Failed',
      value: dashboardState?.getConsultationsData?.failed ?? 0,
      color: '#FEAF45',
    },
  ];

  // getMembersStatusData
  const membersChart = [
    {
      name: 'Active',
      value: dashboardState?.getMembersStatusData?.MembersActive ?? 0,
      color: '#6377DD',
    },
    {
      name: 'Inactive',
      value: dashboardState?.getMembersStatusData?.MembersInActive ?? 0,
      color: '#5BB4EE',
    },
  ];

  // getGenderData
  const pieChart = [
    {
      name: 'Male',
      value: dashboardState?.getGenderData?.male ?? '',
      color: '#FEAF45',
    },
    {
      name: 'Female',
      value: dashboardState?.getGenderData?.feMale ?? '',
      color: '#50C795',
    },
    {
      name: 'Others',
      value: dashboardState?.getGenderData?.others ?? '',
      color: '#5BB4EE',
    },
  ];

  // getAgeData
  const columnChart = [
    {
      name: 'Below 20',
      value: dashboardState?.getAgeData?.below20 ?? '',
      color: '#50C795',
    },
    {
      name: '20-40',
      value: dashboardState?.getAgeData?.between20_40 ?? '',
      color: '#6377DD',
    },
    {
      name: 'Above 40',
      value: dashboardState?.getAgeData?.above40 ?? '',
      color: '#5BB4EE',
    },
  ];

  // Total Chart
  const chartCard = [
    {
      headerText: 'Consultations',
      subText: getDashboardConsultations(
        dashboardState?.getConsultationsData?.from_date
      ),
      genderAgeText: true,
      centerContent: (
        <Grid container justifyContent="center">
          <Grid item>
            {donutChart.some((chatData) => chatData.value) ? (
              <DonutChart
                data={donutChart}
                label="Consultations"
                backgroundHeight={250}
                backgroundWidth={250}
                innerRadiusValue={50}
                showLegend
                legendFontSize={14}
              />
            ) : (
              <EmptyStateOrg
                IconRequired={<DropBox />}
                emptyStateStyle={{ mt: 5.25 }}
                subHead="No Data"
                subHeadStyle={{ color: 'grey.A500' }}
              />
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      headerText: 'Member Status',
      subText: getDashboardMemberStatus(
        dashboardState?.getConsultationsData?.from_date
      ),
      genderAgeText: true,
      centerContent: (
        <Grid container justifyContent="center">
          <Grid item>
            {membersChart.some((chatData) => chatData.value) ? (
              <DonutChart
                data={membersChart}
                label="Members"
                backgroundHeight={250}
                backgroundWidth={250}
                innerRadiusValue={50}
                showLegend
                legendFontSize={14}
              />
            ) : (
              <EmptyStateOrg
                IconRequired={<DropBox />}
                emptyStateStyle={{ mt: 5.25 }}
                subHead="No Data"
                subHeadStyle={{ color: 'grey.A500' }}
              />
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      headerText: 'Gender',
      genderAgeText: false,
      centerContent: (
        <Grid container justifyContent="center">
          <Grid item>
            <Box mt={1}>
              {pieChart.some((chatData) => chatData.value) ? (
                <PieCharts
                  data={pieChart}
                  backgroundHeight={250}
                  backgroundWidth={250}
                  outerRadiusValue={80}
                  chartFontSize={12}
                  legendFontSize={14}
                  showLegend
                  fontWeight={600}
                />
              ) : (
                <EmptyStateOrg
                  IconRequired={<DropBox />}
                  emptyStateStyle={{ mt: 5.25 }}
                  subHead="No Data"
                  subHeadStyle={{ color: 'grey.A500' }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      ),
    },
    {
      headerText: 'Age',
      genderAgeText: false,
      centerContent: (
        <Grid container>
          <Grid item xs={12}>
            <Box mt={1} ml={-1}>
              {columnChart.some((chatData) => chatData.value) ? (
                <Box display="flex" justifyContent="start">
                  <ColumnChart
                    data={columnChart}
                    height={250}
                    width={400}
                    barSize={60}
                    showLegend
                    legendFontSize={14}
                    showTooltip={false}
                  />
                </Box>
              ) : (
                <Box display="flex" justifyContent="center">
                  <EmptyStateOrg
                    IconRequired={<DropBox />}
                    emptyStateStyle={{
                      mt: 5.25,
                    }}
                    subHead="No Data"
                    subHeadStyle={{ color: 'grey.A500' }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      ),
    },
  ];

  const downloadURI = (uri) => {
    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadButtonClick = async (val, index) => {
    setDashBoardIndex(index);
    if (val === 'Consultations') {
      // debugger;
      queryClient.invalidateQueries({
        queryKey: ['consultation-download'],
      });
      const response = await getConsultationsDownload(
        dashboardState?.date?.start_date ?? '',
        dashboardState?.date?.end_date ?? ''
      );
      response
        ? downloadURI(response)
        : toast(`This organiozation have no Consultations`, { icon: '⚠️' });
    } else if (val === 'Member Status') {
      queryClient.invalidateQueries({
        queryKey: ['user-status-download'],
      });
      const response = await getMemberStatusDownload(
        dashboardState?.date?.start_date ?? '',
        dashboardState?.date?.end_date ?? ''
      );
      response
      ? downloadURI(response)
      : toast(`This organiozation have no Member Status`, { icon: '⚠️' });
 
    } else {
      return false;
    }

  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['dashboard'],
    });
    getAllDasboardData();
  }, []);

  return (
    <Box>
      {loading ? (
        <Grid container mt={2} columnSpacing={2}>
          {Array(4)
            .fill(1)
            .map((i) => (
              <Grid mb={2} key={i} item xs={6}>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: 'primaryTints.300',
                    '&::after': {
                      background:
                        'linear-gradient( 90deg,transparent,rgba(255,255,255, 0.3),transparent)',
                    },
                  }}
                  width="100%"
                  height={300}
                />
              </Grid>
            ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            rowGap: 2,
            gap: 1.6,
            py: 2,
          }}
        >
          {chartCard?.map((value, index) => (
            <Box key={index}>
              <ChartCard
                data={value}
                index={index}
                loadingTap={index === dashboardIndex}
                ō={
                  index === 0
                    ? consultationsLoading
                    : index === 1
                    ? memberStatusLoading
                    : ''
                }
                downloadButtonClick={downloadButtonClick}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
