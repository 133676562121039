/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-sparse-arrays */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import toast from 'react-hot-toast';
import { create } from 'zustand';

export const useContract = create((set, get) => ({
  contractData: [],
  filterContractData: [],
  startDate: '',
  endDate: '',
  contractCount: '',
  options: {
    offset: 0,
    limit: 10,
  },
  status: '',
  loading: null,
  error: null,
  getAllContractDetails: async () => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      set({ loading: true });
      const { options, status } = get();

      const { data } = await queryClient.fetchQuery({
        queryKey: ['getAllContract'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/organization/getAllContract?id=${tokenData?.organization_id}&status=${status}&offset=${options?.offset}&limit=${options?.limit}`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      let array = [];
      for (const tableData of data?.contract_data) {
        let object = {
          id: tableData?.id ?? '',
          contractStartDate: `${tableData?.contract_start_date} to ${tableData?.contract_end_date}`,
          Members: tableData?.contract_members ?? '',
          Amount: tableData?.contract_amount ?? '',
          Status: tableData?.status ?? '',
          Invoice: tableData?.mou_upload ?? '',
          purchasedOn: tableData?.purchased_on ?? '',
        };
        array.push(object);
      }
      set({
        loading: false,
        contractData: array,
        filterContractData: array,
        contractCount: data?.count ?? '',
      });
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },
  updateDataReport: (date) => {
    set({
      startDate: date?.startDate,
      endDate: date?.endDate,
    });
  },
  updateStatusReport: (status) => {
    set({
      status,
    });
  },
  setContractFilter: (filter) => {
    const { contractData } = get();
    const newcontractData = contractData.filter((data) =>
      filter === 'All' ? true : data.Status === filter
    );
    set({ filterContractData: newcontractData });
  },
  updateStateData: (key, value) => {
    const { options } = get();
    set({
      options: {
        ...options,
        [key]: value,
      },
    });
  },
}));
