/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-key */
import { DownloadsIcon } from '@atoms/icons';
import { endWeekMonth, startWeekMonth } from '@hc/dayjs';
import { useDashboard } from '@hc/store';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { Loader, TickIcon } from '../../../atoms';
import { chartCard_Style } from './style';

export function ChartCard(props) {
  const {
    data = {},
    className = '',
    loadingTap,
    index,
    isLoading,
    downloadButtonClick = () => {},
    ...rest
  } = props;

  // Store Data
  const { getConsultationsStatus, updateDateState, getMemberStatus } =
    useDashboard((state) => ({
      getConsultationsStatus: state.getConsultationsStatus,
      getMemberStatus: state.getMemberStatus,
      updateDateState: state.updateDateState,
    }));

  const selection = [
    {
      value: 'over_all',
      label: 'Over All',
    },
    {
      value: 'this_week',
      label: 'This Week',
    },
    {
      value: 'this_month',
      label: 'This Month',
    },
  ];

  const [open, setOpen] = useState(selection[0]);

  const handleChange = async (value, data) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    if (data?.headerText === 'Consultations') {
      if (value?.value === 'over_all') {
        queryClient.invalidateQueries({
          queryKey: ['consultations-status'],
        });
        updateDateState('date', { start_date: '', end_date: '' });
        await getConsultationsStatus({
          organization_id: tokenData?.organization_id,
          start_date: '',
          end_date: '',
        });
      } else if (value?.value === 'this_week') {
        const paylaod = {
          organization_id: tokenData?.organization_id,
          start_date: new Date(startWeekMonth()).toJSON(),
          end_date: new Date(endWeekMonth()).toJSON(),
        };
        updateDateState('date', {
          start_date: new Date(startWeekMonth()).toJSON(),
          end_date: new Date(endWeekMonth()).toJSON(),
        });
        queryClient.invalidateQueries({
          queryKey: ['consultations-status'],
        });
        await getConsultationsStatus(paylaod);
      } else if (value?.value === 'this_month') {
        const paylaod = {
          organization_id: tokenData?.organization_id,
          start_date: new Date(startWeekMonth()).toJSON(),
          end_date: new Date(endWeekMonth()).toJSON(),
        };
        updateDateState('date', {
          start_date: new Date(startWeekMonth()).toJSON(),
          end_date: new Date(endWeekMonth()).toJSON(),
        });
        queryClient.invalidateQueries({
          queryKey: ['consultations-status'],
        });
        await getConsultationsStatus(paylaod);
      }
      setOpen(value);
    } else {
      if (value?.value === 'over_all') {
        queryClient.invalidateQueries({
          queryKey: ['members-status'],
        });
        updateDateState('date', {
          start_date: '',
          end_date: '',
        });
        await getMemberStatus({
          organization_id: tokenData?.organization_id,
          start_date: '',
          end_date: '',
        });
      } else if (value?.value === 'this_week') {
        const paylaod = {
          organization_id: tokenData?.organization_id,
          start_date: new Date(startWeekMonth()).toJSON(),
          end_date: new Date(endWeekMonth()).toJSON(),
        };
        updateDateState('date', {
          start_date: new Date(startWeekMonth()).toJSON(),
          end_date: new Date(endWeekMonth()).toJSON(),
        });
        queryClient.invalidateQueries({
          queryKey: ['members-status'],
        });
        await getMemberStatus(paylaod);
      } else if (value?.value === 'this_month') {
        const paylaod = {
          organization_id: tokenData?.organization_id,
          start_date: new Date(startWeekMonth()).toJSON(),
          end_date: new Date(endWeekMonth()).toJSON(),
        };
        updateDateState('date', {
          start_date: new Date(startWeekMonth()).toJSON(),
          end_date: new Date(endWeekMonth()).toJSON(),
        });
        queryClient.invalidateQueries({
          queryKey: ['members-status'],
        });
        await getMemberStatus(paylaod);
      }
      setOpen(value);
    }
  };

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={chartCard_Style.chartCardWrapperSx}>
        <Box sx={chartCard_Style.cardSx}>
          <Box sx={chartCard_Style.rootCardSx}>
            <Box>
              <Typography sx={chartCard_Style.headerTextSx}>
                {data?.headerText}
              </Typography>
              <Typography sx={chartCard_Style.subTextSx}>
                {data?.subText}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {data?.headerText === 'Consultations' ||
              data?.headerText === 'Member Status' ? (
                <Box>
                  <Select
                    id="dropDown"
                    value={open?.value}
                    IconComponent={BiChevronDown}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          boxShadow: '0px 0px 4px 2px #E3E3E3',
                        },
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& .MuiSvgIcon-root': {
                        display: 'none',
                      },
                      fontSize: '12px',
                    }}
                  >
                    {selection?.map((val, i) => (
                      <MenuItem
                        key={i}
                        onClick={() => handleChange(val, data)}
                        value={val?.value}
                        //   sx={leaderBoard_style.menuListSx}
                      >
                        {open?.value === val?.value ? <TickIcon /> : null}
                        &nbsp;{val?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              ) : (
                ''
              )}

              <Box>
                {data?.genderAgeText && (
                  <Box>
                    {loadingTap && isLoading ? (
                      <Loader size={17} rootStyle={{ p: 0 }} />
                    ) : (
                      //  TODO: want to add in feature *
                      <a style={{ textDecoration: 'none', cursor: 'pointer' }}>
                        <DownloadsIcon
                          onClick={() =>
                            downloadButtonClick(data?.headerText, index)
                          }
                        />
                      </a>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box>{data?.centerContent}</Box>
        </Box>
      </Box>
    </Box>
  );
}

ChartCard.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  loadingTap: PropTypes.bool,
  index: PropTypes.number,
  isLoading: PropTypes.bool,
  downloadButtonClick: PropTypes.func,
};
