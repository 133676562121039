/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { Dropdown, Label, TableArrowIcon } from '@atoms';
import { useMember } from '@hc/store';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { upload_mapping_style } from './style';

function UploadMapping(props) {
  const {
    className = '',
    rootStyle = {},
    Onchange = () => {},
    // handleSelect = () => {},
    // contract = '',
    checkValid = '',
    value = [],
    ...rest
  } = props;

  // const { contract_Count, getMemberCount } = useMember((state) => ({
  //   contract_Count: state.contract_Count,
  //   getMemberCount: state.getMemberCount,
  // }));

  // const { profileView } = useOrgProfile((state) => ({
  //   profileView: state.profileView,
  // }));

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const mappingColumnData = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 160,
      isRequired: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 210,
      isRequired: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 160,
      isRequired: true,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 160,
      isRequired: false,
    },
  ];

  const { dropDownData } = useMember((state) => ({
    dropDownData: state.dropDownData,
  }));

  // let newContract_Count = [];
  // for (const object of contract_Count) {
  //   if (object.remaining_member_count > 0) {
  //     let obj = {
  //       label: `${object.contract_id} (${object.remaining_member_count})`,
  //       value: object.id,
  //     };
  //     newContract_Count.push(obj);
  //   }
  // }

  // useEffect(() => {
  //   getMemberCount(tokenData.organization_id);
  // }, []);

  return (
    <Box
      sx={{ ...upload_mapping_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box py={1.5}>
        <Typography fontSize={16} fontWeight={500}>
          Column Mapping
        </Typography>
      </Box>
      <Box sx={upload_mapping_style.boxRootSx}>
        <Grid container display="flex">
          <Grid item xs={5.5} p={1.6}>
            <Typography fontSize={14} fontWeight={500}>
              Filed in uploaded file
            </Typography>
          </Grid>
          <Grid item xs={1} p={1.6} alignSelf="center">
            <TableArrowIcon />
          </Grid>
          <Grid item xs={5.5} p={1.6}>
            <Typography fontSize={14} fontWeight={500}>
              Import Field
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        {mappingColumnData?.map((data, index) => (
          <Grid container display="flex" key={index}>
            <Grid item xs={5.5} p={1.6} display="flex" alignSelf="center">
              <Box>
                {/* <CheckBox onChange={(e)=>onCheckClick(e,index,data?.field)} value={data.field}  />           */}
              </Box>
              <Label
                rootStyle={upload_mapping_style.mapLabelSx}
                isRequired={!!data.isRequired}
              >
                {data.headerName}
              </Label>
            </Grid>
            <Grid item xs={1} p={1.6} alignSelf="center">
              <TableArrowIcon />
            </Grid>
            <Grid item xs={5.5} p={1.6}>
              <Dropdown
                datatestid={`dropdown_${index}`}
                value={value?.data}
                onChange={(e) => Onchange(e, index, data?.headerName)}
                selectOption={dropDownData}
              />
            </Grid>
          </Grid>
        ))}

        {/* <Grid container display="flex">
          <Grid item xs={5.5} p={1.6} display="flex" alignSelf="center">
            <Box> */}
        {/* <CheckBox onChange={(e)=>onCheckClick(e,index,data?.field)} value={data.field}  />           */}
        {/* </Box>
            <Label rootStyle={upload_mapping_style.mapLabelSx} isRequired>
              contract
            </Label>
          </Grid>
          <Grid item xs={1} p={1.6} alignSelf="center">
            <TableArrowIcon />
          </Grid>
          <Grid item xs={5.5} p={1.6}>
            <Dropdown
              datatestid="memberContract"
              value={contract}
              onChange={(e) => handleSelect(e)}
              selectOption={newContract_Count}
            />
          </Grid>
        </Grid> */}

        <Typography sx={upload_mapping_style.errorLabelSx}>
          {checkValid}
        </Typography>
      </Box>
    </Box>
  );
}

export { UploadMapping };
UploadMapping.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.string,
  columnData: PropTypes.array,
  Onchange: PropTypes.func,
  onCheckClick: PropTypes.func,
  checkValid: PropTypes.string,
  value: PropTypes.array,
  // handleSelect: PropTypes.func,
  // contract: PropTypes.string,
};
