import { useMember } from '@hc/store';
import { TableSkeletonLoader } from '@hc/ui/components';
import {
  AddMemberDrawer,
  MembersTable,
  MembersTopContainer
} from '@hc/ui/components/organization';
import { queryClient } from '@hc/utils';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

export default function Members(props) {
  const { className = '', ...rest } = props;

  // Store Data - Members Data
  const {
    member,
    getAllOrganizationMembers,
    addOrganizationMember,
    options,
    clearOrganizationMember,
    updateOrganizationError,
    loading,
    // contract_Count,
  } = useMember((state) => ({
    member: state.member,
    options: state.options,
    loading: state.loading,
    getAllOrganizationMembers: state.getAllOrganizationMembers,
    addOrganizationMember: state.addOrganizationMember,
    updateOrganizationError: state.updateOrganizationError,
    clearOrganizationMember: state.clearOrganizationMember,
    // contract_Count: state.contract_Count,
  }));

  // General Hooks
  const [addMemberDrawer, setAddMemberDrawer] = useState(false);

  const closeMemberDrawer = () => {
    setAddMemberDrawer(false);
    clearOrganizationMember();
  };

  const isIamValidToSave = () => {
    const memberCopy = JSON.parse(JSON.stringify(member));
    let isValid = true;
    const error = memberCopy?.error;
    // checking name
    if (!member?.name) {
      isValid = false;
      error.name = 'Please enter your name..';
    } else {
      error.name = '';
    }
    // checking gender
    if (
      member?.gender?.label === undefined ||
      member?.gender?.label?.length === 0
    ) {
      isValid = false;
      error.gender = 'Please select gender';
    } else {
      error.gender = '';
    }

    // checking email
    const filter = /\S+@\S+\.\S+/;
    if (!member?.email_id?.length > 0 && !filter.test(member?.email_id)) {
      isValid = false;
      error.email_id = 'Please enter the valid mail';
    } else {
      error.email_id = '';
    }

    // Checking Mobile Number
    if (member?.mobile_no.length === 0) {
      isValid = false;
      error.mobile_no = 'Enter a valid 10 digit mobile number';
    } else if (member?.mobile_no.length < 10) {
      isValid = false;
      error.mobile_no = 'Enter a valid 10 digit mobile number';
    } else {
      error.mobile_no = '';
    }
    // Checking contract info
    // if (!member?.contract_id) {
    //   isValid = false;
    //   error.contract_id = 'please select contract';
    // } else {
    //   error.contract_id = '';
    // }

    updateOrganizationError(error);
    return isValid;
  };
  const addMember = async () => {
    const result = isIamValidToSave();
    if (result) {
      const response = await addOrganizationMember(member, true);
      if (response?.data?.statusCode === '200') {
        setAddMemberDrawer(false);
        clearOrganizationMember();
        queryClient.invalidateQueries({
          queryKey: ['organization_members/all'],
        });
        getAllOrganizationMembers(
          options?.search,
          options?.offset,
          options?.limit,
        );
      }
    }
  };

  const handleClick = () => {
    // if (Array.isArray(contract_Count) && contract_Count.length > 0) {
    setAddMemberDrawer(true);
    // } else {
    //   toast.error('The contract is either expired or does not exist');
    // }
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['organization_members/all'],
    });
    getAllOrganizationMembers(options?.search, options?.offset, options?.limit);
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      px={3}
      py={3}
      sx={{ height: '100vh', overflowY: 'scroll' }}
    >
      <Box mt={1}>
        <Grid container>
          <Grid item xs={12}>
            <MembersTopContainer handleClick={handleClick} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={loading ? { display: 'none' } : ''}>
              <MembersTable />
            </Box>
          </Grid>
        </Grid>

        {/* Add Member Drawer */}
        <Box>
          <AddMemberDrawer
            addMemberDrawer={addMemberDrawer}
            closeMemberDrawer={closeMemberDrawer}
            addMember={addMember}
          />
        </Box>
      </Box>
      {loading && (
        <Box>
          <TableSkeletonLoader />
        </Box>
      )}
    </Box>
  );
}
