export const leaderBoard_style = {
  titleSx: {
    fontSize: '16px',
    fontWeight: 100,
    display: 'flex',
  },
  categorySx: {
    display: 'flex',
  },
  rankSx: {
    display: 'flex',
  },
  headerSx: {
    display: 'flex',
  },
  dateSx: {
    fontSize: '12px',
    color: '#5C6266',
  },
  weekSx: {
    fontSize: '12px',
    color: 'text.secondary',
  },
  menuListSx: {
    fontSize: '14px',
    color: 'text.primary',
    boxShadow: '0px 4px 16px #0000000A',
  },
  cardListSx: {
    height: {
      sm: 500,
      lg: '70vh',
    },
    overflowY: 'scroll',
  },
};
