export const membersCard_Style = {
  cardSx: {
    boxShadow: '0px 4px 16px #0000000A',
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    bgcolor:"#fff"
  },
  membersWrapperSx: {
    display: 'flex',
    justifyContent: 'space-between',
    pt: 1,
    pb: 2,
    px: 3,
  },
  dividerSx: {
    mt: 1.5,
    height: '62px',
    border: '0.5px solid',
    backgroundColor: '#F0F0F0',
    borderColor: '#F0F0F0',
  },
  skeletonRoot: {
    borderRadius: 2,
    backgroundColor: 'primaryTints.300',
    '&::after': {
      background:
        'linear-gradient( 90deg,transparent,rgba(255,255,255, 0.3),transparent)',
    },
  },
  membersHeadingSx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: 2,
    py: 1,
  },
  numberSx: {
    fontSize: '32px',
    color: 'text.primary',
    fontWeight: 600,
  },
  membersJoinedSx: {
    fontSize: '12px',
    color: 'text.hint',
  },
  boxSx: {
    display: 'flex',
    alignItems: 'center',
  },
  invitesLeftCountSx: {
    fontSize: '14px',
    color: 'primary.main',
    fontWeight: 600,
    backgroundColor: 'primaryTints.100',
    px: 0.5,
    py: 0.3,
    borderRadius: '4px',
  },
  invitesLeftTextSx: {
    fontSize: '12px',
    color: 'primary.main',
  },
  membersTextSx: {
    fontSize: '16px',
    color: 'text.primary',
    fontWeight: 600,
  },
  iconsWithTextSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
  },
};
