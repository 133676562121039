import { useOrganizationOnboarding } from '@hc/store';
import { Button, HealthCirclesLogo, Input, Label } from '@hc/ui/atoms';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { reset_password_content_card_style } from './style';

export function ResetPasswordContentCard() {
  const [searchParams] = useSearchParams();
  const {
    user,
    loading,
    resetPassword,
    updateOnboarding,
    updateErrorOnboarding,
  } = useOrganizationOnboarding(
    (state) => ({
      user: state.user,
      loading: state.loading,
      setUser: state.setUser,
      resetPassword: state.resetPassword,
      updateOnboarding: state.updateOnboarding,
      updateErrorOnboarding: state.updateErrorOnboarding,
      updateStateOnBoarding: state.updateStateOnBoarding,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  // General hooks
  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };
  const [showConfirmPassword, setConfirmPassword] = useState();
  const handleClickshowConfirmPassword = () => {
    setConfirmPassword(!showConfirmPassword);
  };

  // const [data, setData] = React.useState(user);

  const handleChange = (val, key) => {
    updateOnboarding(key, val);
  };

  const resetPasswordLink = async () => {
    const error = user?.error;
    if (!user?.password) {
      error.password = 'Enter the password';
      updateErrorOnboarding(error);
      return;
    }
    error.password = '';

    if (user?.password.length < 8) {
      error.password = 'Password must be at least 8 characters long';
      updateErrorOnboarding(error);
      return;
    }
    if (
      !user?.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
      )
    ) {
      error.password =
        'Password must contain uppercase and lowercase letters, numbers, and special characters';
      updateErrorOnboarding(error);
      return;
    }
    error.password = '';

    // Checking Cofirm password
    if (!user?.confirmPassword) {
      error.confirmPassword = 'Enter the confirm password';
      updateErrorOnboarding(error);
      return;
    }
    error.confirmPassword = '';

    // Checking confirm and password are same
    if (user?.confirmPassword !== user?.password) {
      error.confirmPassword = "Password doesn't matching, please check again";
      updateErrorOnboarding(error);
      return;
    }
    error.confirmPassword = '';
    error.password = '';
    error.email = '';

    // calling the signup api
    await resetPassword(user);
    // window.location.href='/'
  };

  useEffect(() => {
    localStorage.clear();
    if (searchParams.get('token')?.length > 0) {
      localStorage.setItem(
        localStorageKeys.authToken,
        searchParams.get('token')
      );
      const tokenData = parseJwt(searchParams.get('token'));
      // setData(tokenData);
      updateOnboarding('email', tokenData?.email_id);
    }
  }, []);

  return (
    <Box sx={reset_password_content_card_style.CardcontentSx}>
      <Box sx={reset_password_content_card_style.logowithtextSx}>
        <IconButton disableRipple>
          <HealthCirclesLogo
            rootStyle={reset_password_content_card_style.hcLogoSx}
          />
        </IconButton>
        <Typography sx={reset_password_content_card_style.companytypoSx}>
          Health Circles
        </Typography>
      </Box>
      <Typography sx={reset_password_content_card_style.createPasswordSx}>
        Reset Password
      </Typography>
      <Typography sx={reset_password_content_card_style.subTextSx}>
        Please provide a new password for your account
      </Typography>
      <Box sx={reset_password_content_card_style.inputGroupSx}>
        <Label htmlFor="password">New Password</Label>
        <Input
          id="password"
          type={showpassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e.target.value, 'password')}
          isError={user?.error.password}
          errorMessage={user?.error.password}
          value={user?.password}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword()}
              edge="end"
            >
              {showpassword ? (
                <MdVisibilityOff
                  htmlColor="#848484"
                  sx={reset_password_content_card_style.eyeSx}
                />
              ) : (
                <MdVisibility
                  htmlColor="#848484"
                  sx={reset_password_content_card_style.eyeSx}
                />
              )}
            </IconButton>
          }
        />
      </Box>
      <Box sx={reset_password_content_card_style.inputGroupSx}>
        <Label htmlFor="confirmPassword">Confirm New Password</Label>
        <Input
          id="confirmPassword"
          type={showConfirmPassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
          isError={!!user?.error.confirmPassword}
          errorMessage={user?.error.confirmPassword}
          value={user?.confirmPassword}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickshowConfirmPassword()}
              edge="end"
            >
              {showConfirmPassword ? (
                <MdVisibilityOff
                  htmlColor="#848484"
                  sx={reset_password_content_card_style.eyeSx}
                />
              ) : (
                <MdVisibility
                  htmlColor="#848484"
                  sx={reset_password_content_card_style.eyeSx}
                />
              )}
            </IconButton>
          }
        />
      </Box>
      <Box sx={{ mt: 3, display: 'grid', gap: 3 }}>
        <Button
          fullWidth
          onClick={() => resetPasswordLink()}
          loading={loading}
          buttonStyle={reset_password_content_card_style.buttonStyleSx}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
