/* eslint-disable camelcase */
export const ContractsHistory_style = {
  headerSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleSx: {
    color: 'text.primary',
    fontSize: '16px',
    fontWeight: 'medium',
  },
  daterangeSx: {
    width: '250px',
  },
};
