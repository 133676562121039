export const emptyState_style = {
  emptyStateSx: {
    textAlign: 'center',
  },
  mainHeadSx: {
    fontWeight: 600,
    pb: 1,
    color: 'text.primary',
    textAlign: 'center',
  },
  subHeadSx: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'text.secondary',
    textAlign: 'center',
  },
};
