import { useOrganizationOnboarding } from '@hc/store';
import { Button, HealthCirclesLogo, Input, Label } from '@hc/ui/atoms';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useLocation, useSearchParams } from 'react-router-dom';

import { onboarding_content_card_style } from './style';

export function OnboardingContentCard() {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const {
    user,
    updateOnboarding,
    updateErrorOnboarding,
    signUp,
    verifyOnboardUrl,
    loading,
  } = useOrganizationOnboarding(
    (state) => ({
      signUp: state.signUp,
      user: state.user,
      loading: state.loading,
      updateOnboarding: state.updateOnboarding,
      verifyOnboardUrl: state.verifyOnboardUrl,
      updateErrorOnboarding: state.updateErrorOnboarding,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const [data, setData] = useState({});
  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };
  const [showConfirmPassword, setConfirmPassword] = useState();
  const handleClickshowConfirmPassword = () => {
    setConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (val, key) => {
    updateOnboarding(key, val);
  };

  const onSignUp = async () => {
    const error = user?.error;
    if (!user?.password) {
      error.password = 'Enter the password';
      updateErrorOnboarding(error);
      return;
    }
    error.password = '';

    if (user?.password.length < 8) {
      error.password = 'Password must be at least 8 characters long';
      updateErrorOnboarding(error);
      return;
    }
    if (
      !user?.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
      )
    ) {
      error.password =
        'Password must contain uppercase and lowercase letters, numbers, and special characters';
      updateErrorOnboarding(error);
      return;
    }
    error.password = '';

    // Checking Cofirm password
    if (!user?.confirmPassword) {
      error.confirmPassword = 'Enter the confirm password';
      updateErrorOnboarding(error);
      return;
    }
    error.confirmPassword = '';

    // Checking confirm and password are same
    if (user?.confirmPassword !== user?.password) {
      error.confirmPassword = "Password doesn't matching, please check again";
      updateErrorOnboarding(error);
      return;
    }
    error.confirmPassword = '';
    error.confirmPassword = '';
    error.password = '';
    error.email = '';
    // calling the signup api
    signUp(user);
  };

  const checkOnboardURL = async () => {
    await verifyOnboardUrl();
  };

  useEffect(() => {
    if (searchParams.get('token')?.length > 0) {
      localStorage.clear();
      localStorage.setItem(
        localStorageKeys.authToken,
        searchParams.get('token')
      );
      checkOnboardURL();
      const tokenData = parseJwt(searchParams.get('token'));
      setData(tokenData);
      updateOnboarding('email', tokenData?.email_id);
    }
  }, [location]);

  return (
    <Box sx={onboarding_content_card_style.CardcontentSx}>
      <IconButton disableRipple>
        <HealthCirclesLogo rootStyle={onboarding_content_card_style.hcLogoSx} />
      </IconButton>
      <Typography sx={onboarding_content_card_style.createPasswordSx}>
        Create Password
      </Typography>
      <Box>
        <Label htmlFor="emailInput">Email</Label>
        <Input
          value={user?.email ?? ''}
          id="email"
          isError={user?.error.email !== ''}
          helperText={user?.error.email}
          onChange={(value) => handleChange(value.target.value, 'email')}
          isReadonlyCC={data?.organization_name?.length > 0}
          disabled={data?.organization_name?.length > 0}
        />
      </Box>
      <Box sx={onboarding_content_card_style.inputGroupSx}>
        <Label htmlFor="password" isRequired>
          Create password
        </Label>
        <Input
          id="password"
          type={showpassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e.target.value, 'password')}
          isError={user?.error.password}
          errorMessage={user?.error.password}
          value={user?.password}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword()}
              edge="end"
            >
              {showpassword ? (
                <MdVisibilityOff htmlColor="#848484" />
              ) : (
                <MdVisibility htmlColor="#848484" />
              )}
            </IconButton>
          }
        />
      </Box>
      <Box sx={onboarding_content_card_style.inputGroupSx}>
        <Label htmlFor="confirmPassword" isRequired>
          Confirm password
        </Label>
        <Input
          id="confirmPassword"
          type={showConfirmPassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
          isError={!!user?.error.confirmPassword}
          errorMessage={user?.error.confirmPassword}
          value={user?.confirmPassword}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickshowConfirmPassword()}
              edge="end"
            >
              {showConfirmPassword ? (
                <MdVisibilityOff htmlColor="#848484" />
              ) : (
                <MdVisibility htmlColor="#848484" />
              )}
            </IconButton>
          }
        />
      </Box>
      <Box sx={{ mt: 3, display: 'grid', gap: 3 }}>
        <Button
          fullWidth
          buttonStyle={onboarding_content_card_style.buttonStyleSx}
          onClick={() => onSignUp()}
          loading={loading}
        >
          Sign Up
        </Button>
      </Box>
    </Box>
  );
}
