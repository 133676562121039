import { DownloadsIcon, Table } from '@atoms';
import { getDateFormatToString } from '@hc/dayjs';
import { useContract } from '@hc/store';
import { queryClient } from '@hc/utils';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import isEqual from 'react-fast-compare';
import { Pagination } from '../../../backoffice/pagination';
import { TableSkeletonLoader } from '../../../Skeleton/tableSkeletonLoader';

export function ContractTable() {
  // Store Data
  const {
    filterContractData,
    getAllContractDetails,
    options,
    contractCount,
    updateStateData,
    loading,
  } = useContract(
    (state) => ({
      contractCount: state.contractCount,
      filterContractData: state.filterContractData,
      options: state.options,
      updateStateData: state.updateStateData,
      getAllContractDetails: state.getAllContractDetails,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const onLimitChange = async (limit, offset) => {
    updateStateData('limit', limit);
    updateStateData('offset', offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllContract'],
    });
    await getAllContractDetails();
  };

  const onOffsetchange = async (offset) => {
    updateStateData('offset', offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllContract'],
    });
    await getAllContractDetails();
  };

  const onClickFnc = () => {
    toast.success('Contract Invoice Downloaded Successfully!');
  };

  const columns = [
    {
      field: 'contractStartDate',
      headerName: 'Contract Period',
      width: 300,
    },
    {
      field: 'purchasedOn',
      headerName: 'Purchased On',
      width: 180,
      valueGetter: (params) =>
        getDateFormatToString(params.row.purchasedOn, 'lll')
    },
    {
      field: 'Members',
      headerName: 'Members',
      type: 'number',
      width: 150,
      align: 'center',
    },
    {
      field: 'Amount',
      headerName: 'Amount',
      type: 'number',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: '12px',
            borderRadius: '6px',
            padding: '4px 8px',
          }}
          backgroundColor={`${
            params.row.Status === 'Active' ? '#E8FCF0' : '#FEEAEA'
          }`}
          color={`${params.row.Status === 'Active' ? '#25C460' : '#F44F5A'}`}
        >
          {params.row.Status}
        </Typography>
      ),
    },
    {
      field: 'Invoice',
      headerName: 'Invoice',
      width: 90,
      renderCell: (params) => (
        <IconButton disableRipple sx={{ margin: 'auto' }} onClick={() => onClickFnc()}>
          <a
            id={params.row.id}
            href={`${params.row?.Invoice}`}
            download
            style={{ textDecoration: 'none' }}
          >
            <DownloadsIcon />
          </a>
        </IconButton>
      ),
    },
  ];
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['getAllContract'],
    });
    getAllContractDetails();
  }, []);
  return (
    <Box>
      {loading ? (
        <Box>
          <TableSkeletonLoader />
        </Box>
      ) : (
        <Box>
          <Table
            rows={filterContractData}
            columns={columns}
            rootStyle={{ height: '60vh' }}
            emptyState
            hideFooter
            customTableStyle={{
              '& .MuiDataGrid-row': {
                borderBottom: '2px solid',
                borderColor: 'grey.400',
              },
              '& .MuiDataGrid-main': {
                border: '1px solid',
                borderColor: 'grey.400',
                bgcolor: '#FFFFFF',
                borderRadius: '6px',
              },
              '& .MuiDataGrid-footerContainer': {
                display: 'none',
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#EDEFF2',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid',
                borderColor: 'grey.400',
              },
            }}
            autoHeight={false}
          />
          <Stack
            flexDirection="row"
            justifyContent="end"
            sx={{ width: '100%' }}
          >
            <Pagination
              totalCount={contractCount}
              onLimitChange={onLimitChange}
              onOffsetchange={onOffsetchange}
              offset={options?.offset}
              rowsPerPage={options?.limit}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}
