import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
} from 'recharts';
import { RenderLegend } from '../helperFunctions';
import { chart_style } from '../style';

function PieCharts(props) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const {
    className = '',
    data = [],
    backgroundHeight,
    outerRadiusValue,
    backgroundWidth,
    showLegend,
    legendFontSize,
    chartFontSize,
    fontWeight,
    chartType = '',
    rootStyle = {},
    ...rest
  } = props;

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    payload,
    midAngle,
    startAngle,
    endAngle,
    fill,
    innerRadius,
    outerRadius,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.sin(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />

        <text
          x={x}
          y={y}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fontSize={chartFontSize}
          fontWeight={fontWeight}
        >
          {payload.name} {payload.value}
        </text>
      </g>
    );
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <Box
      sx={{ ...chart_style.chartSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <ResponsiveContainer height={backgroundHeight} width={backgroundWidth}>
        <PieChart fontFamily="Poppins, sans-serif">
          {showLegend && (
            <Legend
              verticalAlign="bottom"
              content={
                <RenderLegend
                  data={data}
                  chartType={chartType}
                  legendFontSize={legendFontSize}
                />
              }
            />
          )}
          <Pie
            activeIndex={activeIndex}
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            activeShape={renderCustomizedLabel}
            outerRadius={outerRadiusValue}
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}
export { PieCharts };

PieCharts.propTypes = {
  chartType: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  backgroundWidth: PropTypes.number,
  backgroundHeight: PropTypes.number,
  outerRadiusValue: PropTypes.number,
  showLegend: PropTypes.bool,
  chartFontSize: PropTypes.number,
  legendFontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  rootStyle: PropTypes.object,
};
