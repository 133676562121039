/* eslint-disable camelcase */
import { OnboardingContentCard } from '@hc/ui/components';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { signUp_style } from './style';

export default function Onboarding(props) {
  const { className = '', ...rest } = props;
  return (
    <Box className={`${className}`} {...rest} sx={signUp_style.rootSx}>
      <Box sx={signUp_style.centerCardSx}>
        <OnboardingContentCard />
      </Box>
    </Box>
  );
}

Onboarding.propTypes={
  className:PropTypes.string,
}