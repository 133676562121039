export const config = {
  auth_url: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_AUTH_API_HOST
  }${import.meta.env.VITE_AUTH_API_BASE_URL}`,
  // Version 1 - Auth API
  auth_url_v1: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_AUTH_API_HOST
  }${import.meta.env.VITE_AUTH_API_BASE_URL_v1}`,

  api_url: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_REST_API_HOST
  }${import.meta.env.VITE_REST_API_BASE_URL}`,
  // Version 1 - Rest API
  api_url_v1: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_REST_API_HOST
  }${import.meta.env.VITE_REST_API_BASE_URL_v1}`,
};

