export const members_top_container_Style = {
  membersTextSx: {
    color: 'text.primary',
    fontSize: '16px',
    fontWeight: 'medium',
  },
  titleSx: {
    fontSize: 20,
  },
  dialogSx: {
    width: '900px',
  },
  uploadButtonSx: {
    display: 'flex',
    width: 128,
    height: 40,
    color: 'primary.main',
    border: '2px solid ',
    boderColor: 'primary.main',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  unSelectAvailabilityButtonSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'primaryTints.A100',
  },
  buttonSx: {
    color: 'primary.main',
    border: '2px solid',
    fontSize: '14px',
    fontWeight: 'medium',
    borderColor: 'primary.main',
    height: '40px',
    width: '128px',
    borderRadius: '4px',
    textTransform: 'capitalize',
  },
  addMemberSx: {
    textTransform: 'capitalize',
    height: '40px',
    width: '124px',
    borderRadius: '4px',
  },
  searchFieldSx: {
    width: '360px',
    '& .MuiOutlinedInput-notchedOutline': {
      px: 2,
      height: '44px',
      border: 'none',
    },
    '&.MuiFormControl-root': {
      backgroundColor: '#fff',
      pb: '4px',
    },
    ' & .MuiOutlinedInput-input': {
      color: 'text.primary',
      backgroundColor: '#fff',
      pt: 0.5,
    },
    '& .MuiOutlinedInput-root': {
      px: 0,
      pt: 0.7,
      backgroundColor: 'tranparent',
      color: '#025140',
      borderRadius: '4px',
      '& fieldset': {
        borderRadius: '4px',
        border: '1.5px solid',
        borderColor: '#C5C5C5',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },
};

// '&.MuiFormControl-root': {
//       backgroundColor: 'transparent',
//     },
//     '& .MuiOutlinedInput-root': {
//       px: 0,
//       pt: 0.7,
//       backgroundColor: 'common.white',
//       borderRadius: '8px',
//       color: 'grey',
//       '& fieldset': {
//         borderRadius: '8px',
//         border: '1.5px solid',
//         borderColor: 'border.main',
//       },
//       '&:hover fieldset': {
//         border: '1.5px solid',
//         borderColor: 'border.hover',
//       },
//       '&.Mui-focused fieldset': {
//         border: '1.5px solid',
//         borderColor: 'border.focus',
//       },
//     },
//     '& .MuiOutlinedInput-input': {
//       padding: '0px 4px 5px 10px',
//       fontSize: '14px',
//     },
//     width: '100%',
//     '& .MuiOutlinedInput-notchedOutline': {
//       border: 'none',
//     },
