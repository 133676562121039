/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-sparse-arrays */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import toast from 'react-hot-toast';
import {create} from 'zustand';

export const useLeaderBoard = create((set, get) => ({
  leaderboardState: { leaderboard: [], startDate: '', endDate: '' },
  loading: false,
  error: null,
  getLeaderBoard: async () => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      const { leaderboardState } = get();
      const { startDate, endDate } = leaderboardState;

      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['leaderBoard'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/get-leaderBoard`,
            {
              organization_id: tokenData?.organization_id ?? '',
              start_date: startDate ?? '',
              end_date: endDate ?? '',
            },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        return set({
          leaderboardState: {
            ...leaderboardState,
            leaderboard: data?.response_data,
          },
          loading: false,
        });
      }
      set({ loading: false });
      return data;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  updateLeaderboardStateStartEndDate: (start, end) => {
    const { leaderboardState } = get();
    set({
      leaderboardState: {
        ...leaderboardState,
        startDate: start,
        endDate: end,
      },
    });
  },
}));
