export const leaderBoardSkeletonLoader_style = {
  skeletonRoot: {
    backgroundColor: 'primaryTints.300',
    '&::after': {
      background:
        'linear-gradient( 90deg,transparent,rgba(255,255,255, 0.3),transparent)',
    },
  },
  rootSx:{
    borderRadius:1,
    border:"1px solid #EEEEEE",
    p:1.5
  }
};
