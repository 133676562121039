export const chartCard_Style = {
  cardSx: {
    backgroundColor: ' #fff',
    color: ' #0E1824',
    transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overflow: 'hidden',
    boxShadow: '0px 4px 16px #0000000A',
    borderRadius: '8px',
    width: ' 100%',
    gap: '12px',
    height: '315px',
  },
  chartCardWrapperSx: {
    display: 'grid',
    gridTemplateRows: '30% 70%',
    rowGap: 2,
  },
  rootCardSx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: 1.5,
  },
  headerTextSx: {
    fontSize: '14px',
    color: 'text.primary',
    fontWeight: 'medium',
    pt: 2,
  },
  subTextSx: {
    fontSize: '10px',
    color: 'text.hint',
    fontWeight: 'normal',
    mt: 0.564,
  },
};
