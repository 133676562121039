/* eslint-disable react/no-unstable-nested-components */
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
} from 'recharts';
import { RenderLegend } from '../helperFunctions';

export function DonutChart(props) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const {
    className = '',
    data = [],
    label = '',
    outerRadiusValue,
    innerRadiusValue,
    legendFontSize,
    backgroundHeight,
    backgroundWidth,
    showLegend,
    chartType = '',
    rootStyle = {},
    ...rest
  } = props;

  function RenderActiveShape(props) {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={0}
          fontSize={26}
          fontWeight={600}
          textAnchor="middle"
        >
          {payload.value}
        </text>
        <text
          x={cx}
          y={cy}
          fontSize={12}
          dy={18}
          textAnchor="middle"
          fill="#5C6266"
        >
          {label}
        </text>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
    
  }

  RenderActiveShape.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    innerRadius: PropTypes.number,
    outerRadius: PropTypes.number,
    startAngle: PropTypes.number,
    endAngle: PropTypes.number,
    fill: PropTypes.string,
    payload: PropTypes.object,
  };



  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <Box sx={{ ...rootStyle }} className={`${className}`} {...rest}>
      <ResponsiveContainer height={backgroundHeight} width={backgroundWidth}>
        <PieChart
          width={backgroundWidth}
          height={backgroundHeight}
          fontFamily="Poppins, sans-serif"
        >
          <Pie
            activeIndex={activeIndex}
            activeShape={RenderActiveShape}
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={innerRadiusValue}
            outerRadius={outerRadiusValue}
            fill="#82ca9d"
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>
          {showLegend && (
            <Legend
              verticalAlign="bottom"
              content={
                <RenderLegend
                  data={data}
                  legendFontSize={legendFontSize}
                  chartType={chartType}
                />
              }
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}

DonutChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  label: PropTypes.string,
  backgroundWidth: PropTypes.number,
  backgroundHeight: PropTypes.number,
  outerRadiusValue: PropTypes.number,
  innerRadiusValue: PropTypes.number,
  showLegend: PropTypes.bool,
  legendFontSize: PropTypes.number,
  chartType: PropTypes.string,
  rootStyle: PropTypes.object,
};
