/* eslint-disable react/display-name */
import { organizationRoutes } from '@hc/routes';
import { localStorageKeys, loginRoutes } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function PrivateRouter(props) {
  const { children } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    // get the authToken from local storage
    const authToken = localStorage.getItem(localStorageKeys?.authToken);

    if (
      !authToken &&
      !loginRoutes.some((route) => route === location?.pathname) &&
      location.pathname !== organizationRoutes.resetpassword
    ) {
      navigate(organizationRoutes.login);
    }

    if (authToken) {
      const data = parseJwt(authToken);
      // If expire date is lesser than current date & time clear the auth token in local storage and redirect to login page
      if (moment().unix() >= data?.exp) {
        localStorage.clear();
        navigate(organizationRoutes?.login);
      } else if (location.pathname === organizationRoutes.onboarding) {
        return setShowComponent(true);
      }
      // if the path is one of the login routes and we have auth token & mobile number verified & have the name redirect to home
      else if (
        location.pathname !== organizationRoutes.forgotpassword &&
        loginRoutes.some((route) => route === location?.pathname)
      ) {
        navigate(organizationRoutes.dashboard);
      }
    }
    setShowComponent(true);
  }, [location]);

  if (showComponent) {
    return children;
  }
  return null;
}

export { PrivateRouter };
