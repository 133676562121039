import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { chart_style } from '../style';
import { RenderLegend } from '../helperFunctions';

function ColumnChart(props) {
  const {
    className = '',
    data = [],
    rootStyle = {},
    height,
    barSize,
    chartType = '',
    showLegend,
    legendFontSize,
    width,
    showTooltip,
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...chart_style.chartSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          fontFamily="Poppins, sans-serif"
          data={data}
          margin={{
            top: 5,
            right: 120,
            left: -20,
            bottom: 5,
          }}
          barSize={barSize}
        >
          <XAxis fontSize={12} dataKey="name" />
          <YAxis fontSize={12} />
          {showTooltip && (
            <Tooltip
              labelStyle={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: legendFontSize,
              }}
              contentStyle={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: legendFontSize,
              }}
              cursor={false}
            />
          )}
          <CartesianGrid strokeDasharray="3 3" />
          <Bar margin={{ top: 50 }} dataKey="value">
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Bar>
          {showLegend && (
            <Legend
              verticalAlign="bottom"
              content={
                <RenderLegend
                  data={data}
                  legendFontSize={legendFontSize}
                  chartType={chartType}
                />
              }
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export { ColumnChart };

ColumnChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })
  ),
  width: PropTypes.number,
  height: PropTypes.number,
  barSize: PropTypes.number,
  showLegend: PropTypes.bool,
  legendFontSize: PropTypes.number,
  chartType: PropTypes.string,
  showTooltip: PropTypes.bool,
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};
