/* eslint-disable camelcase */
export const dashboard_Style = {
  dashboardRootSx: {
    px: 2,
    pt: 2,
  },

  chartBoardSx: {
    maxHeight: {
      sm: 500,
      lg: '84vh',
    },
    borderRadius: '8px',
    overflowY: 'scroll',
  },
  skeletonRoot: {
    borderRadius: 2,
    backgroundColor: 'primaryTints.300',
    '&::after': {
      background:
        'linear-gradient( 90deg,transparent,rgba(255,255,255, 0.3),transparent)',
    },
  },
};
