import { useDashboard } from '@hc/store';
import { DashBoradCharts } from '@hc/ui/components';
import { InsightsCard } from '@hc/ui/components/organization/insightsCard';
import { LeaderBoard } from '@hc/ui/components/organization/leaderBoard';
import { MembersCard } from '@hc/ui/components/organization/membersCard';
import { localStorageKeys, queryClient } from '@hc/utils';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { version } from '../../../package.json';
import { dashboard_Style } from './style';

export default function Dashboard(props) {
  const { className = '', ...rest } = props;

  // Store Data
  const { getAllDasboardData } = useDashboard((state) => ({
    getAllDasboardData: state.getAllDasboardData,
  }));

  const getInitialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['dashboard'],
    });
    await getAllDasboardData();
  };

  useEffect(() => {
    getInitialData();
    localStorage.setItem(localStorageKeys.organizationVersion, version);
  }, []);

  return (
    <Box className={`${className}`} {...rest}>
      <Grid container>
        <Grid item xs={12} sm={7} sx={{ pb: 10 }}>
          <Box sx={dashboard_Style.dashboardRootSx}>
            <Box sx={dashboard_Style.chartBoardSx}>
              <MembersCard />

              <Box>
                <DashBoradCharts />
              </Box>
              <Box sx={{ pb: 3 }}>
                <InsightsCard />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} sx={{ height: '100vh' }}>
          <Box>
            <LeaderBoard />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

Dashboard.propTypes = {
  className: PropTypes.string,
};
