/* eslint-disable camelcase */
import { ResetPasswordContentCard } from '@hc/ui/components';
import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types'
import { resetPassword_Style } from './style';

export default function ResetPassword(props) {
  const { className = '', ...rest } = props;
  return (
    <Box className={`${className}`} {...rest} sx={resetPassword_Style.rootSx}>
      <Box sx={resetPassword_Style.centerCardSx}>
        <ResetPasswordContentCard />
      </Box>
    </Box>
  );
}

ResetPassword.propTypes={
  className:PropTypes.string,
}
