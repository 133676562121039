import { PageNotFound, RootLayout } from '@hc/ui/components';
import Contracts from '@pages/contracts';
import Dashboard from '@pages/dashboard';
import ErrorBoundary from '@pages/errorBoundary';
import ForgotPassword from '@pages/forgotPassword';
import Login from '@pages/login';
import Members from '@pages/members';
import Onboarding from '@pages/onboarding';
import ResetPassword from '@pages/resetPassword';
import { organizationRoutes } from '@hc/routes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PrivateRouter } from './privateRouter';

// import { PrivateRouter } from './privateRouter';

const pathSlicer = (path, sliceStart = 1) => path.slice(sliceStart);

const router = createBrowserRouter([
  {
    path: pathSlicer(organizationRoutes?.login),
    element: (
      <PrivateRouter>
        <RootLayout showLayout={false}>
          <Login />
        </RootLayout>
      </PrivateRouter>
    ),
  },
  {
    path: pathSlicer(organizationRoutes?.onboarding),
    element: (
      <PrivateRouter>
        <RootLayout showLayout={false}>
          <Onboarding />
        </RootLayout>
      </PrivateRouter>
    ),
  },
  {
    path: pathSlicer(organizationRoutes?.forgotpassword),
    element: (
      <PrivateRouter>
        <RootLayout showLayout={false}>
          <ForgotPassword />
        </RootLayout>
      </PrivateRouter>
    ),
  },
  {
    path: pathSlicer(organizationRoutes?.resetpassword),
    element: (
      <PrivateRouter>
        <RootLayout showLayout={false}>
          <ResetPassword />
        </RootLayout>
      </PrivateRouter>
    ),
  },
  {
    path: '/',
    element: (
      <PrivateRouter>
        <RootLayout />
      </PrivateRouter>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: pathSlicer(organizationRoutes?.memberstable),
        element: <Members />,
      },
      {
        path: pathSlicer(organizationRoutes?.contracts),
        element: <Contracts />,
      },
      {
        path: pathSlicer(organizationRoutes?.dashboard),
        element: <Dashboard />,
      },
    ],
  },
  {
    path: '*',
    errorElement: <ErrorBoundary />,
    element: <PageNotFound />,
  },
]);

function RouterApp() {
  return <RouterProvider router={router} />;
}

export default RouterApp;
