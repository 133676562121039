/* eslint-disable camelcase */
import { LoginContentCard } from '@hc/ui/components';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { login_Style } from './style';

export default function Login(props) {
  const { className = '', ...rest } = props;

  return (
    <Box className={`${className}`} {...rest} sx={login_Style.rootSx}>
      <Box sx={login_Style.centerCardSx}>
        <LoginContentCard />
      </Box>
    </Box>
  );
}
Login.propTypes = {
  className: PropTypes.string,
};
