import { useOrganizationOnboarding } from '@hc/store';
import { Button, HealthCirclesLogo, Input, Label } from '@hc/ui/atoms';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordContentCard_style } from './style';

export function ForgotPasswordContentCard() {
  const {
    user,
    forgotPassword,
    loading,
    updateOnboarding,
    updateErrorOnboarding,
    updateStateOnBoarding,
  } = useOrganizationOnboarding(
    (state) => ({
      user: state.user,
      loading: state.loading,
      forgotPassword: state.forgotPassword,
      updateOnboarding: state.updateOnboarding,
      updateErrorOnboarding: state.updateErrorOnboarding,
      updateStateOnBoarding: state.updateStateOnBoarding,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // General Hooks
  const navigate = useNavigate();

  const handleChange = (val, key) => {
    updateOnboarding(key, val);
  };

  const getLink = () => {
    const error = user?.error;
    if (!user?.email) {
      error.email = 'Enter a valid email';
      updateErrorOnboarding(error);
    } else {
      error.password = '';
      error.email = '';
      updateErrorOnboarding(error);
      // calling the signup api
      forgotPassword(user);
    }
  };

  useEffect(() => {
    updateStateOnBoarding();
  }, []);

  const handleClick = () => {
    navigate('/login');
  };

  return (
    <Box sx={forgotPasswordContentCard_style.CardcontentSx}>
      <Box sx={forgotPasswordContentCard_style.logowithtextSx}>
        <IconButton disableRipple>
          <HealthCirclesLogo
            rootStyle={forgotPasswordContentCard_style.hcLogoSx}
          />
        </IconButton>
        <Typography sx={forgotPasswordContentCard_style.companytypoSx}>
          Health Circles
        </Typography>
      </Box>

      <Typography sx={forgotPasswordContentCard_style.createPasswordSx}>
        Forgot Password
      </Typography>

      <Typography sx={forgotPasswordContentCard_style.subTextSx}>
        Provide us the registered phone number to reset your password.
      </Typography>
      <Box>
        <Label htmlFor="email">Email</Label>
        <Input
          value={user?.email ?? ''}
          id="email"
          isError={user?.error.email}
          helperText={user?.error.email}
          onChange={(value) => handleChange(value.target.value, 'email')}
        />
      </Box>
      <Box sx={{ mt: 3, display: 'grid', gap: 3 }}>
        <Button
          fullWidth
          onClick={getLink}
          loading={loading}
          buttonStyle={forgotPasswordContentCard_style.buttonStyleSx}
        >
          Get Link
        </Button>
      </Box>
      <Box>
        <Typography
          sx={forgotPasswordContentCard_style.passwordTextSx}
          onClick={handleClick}
        >
          {' '}
          Remembered the password?{' '}
          <span style={{ fontSize: '14px', color: '#007965', fontWeight: 600 }}>
            Login
          </span>
        </Typography>
      </Box>
    </Box>
  );
}
