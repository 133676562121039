import { CloseIcon } from '@atoms';
import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Profilecard_Style } from './style';

export function ProfileCard(props) {
  const {
    data = [],
    logo = '',
    profileTitle = '',
    className = '',
    onRequestClose,
    ...rest
  } = props;

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={Profilecard_Style.paperSx}>
        <Box sx={Profilecard_Style.cardHeadSx}>
          <Box sx={{ p: 3, float: 'right' }}>
            <CloseIcon
              rootStyle={{ width: '12px', height: '12px', cursor: 'pointer' }}
              onClick={onRequestClose}
              id="close"
            />
          </Box>
        </Box>

        <Box sx={Profilecard_Style.cardBodySx}>
          <Box sx={Profilecard_Style.logoSx}>
            {' '}
            <Box sx={{ display: 'flex' }}>
              <Box sx={Profilecard_Style.avatarSx}>
                <Avatar src={logo} />
              </Box>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                {profileTitle}
              </Typography>
            </Box>
            <Box sx={{ px: 3, pb: 4, pt: 0 }}>
              {data?.map((val, index) => (
                <Box key={index} sx={{ pt: 3 }}>
                  <Box sx={{ display: 'flex', gap: '12px' }}>
                    <Box>{val.icon}</Box>
                    <Box>
                      <Typography sx={Profilecard_Style.headingSx}>
                        {val.heading}
                      </Typography>
                      <Typography sx={Profilecard_Style.descSx}>
                        {val.desc}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
ProfileCard.propTypes = {
  data: PropTypes.array,
  logo: PropTypes.string,
  profileTitle: PropTypes.string,
  className: PropTypes.string,
  onRequestClose: PropTypes.func,
};
