/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  DownloadsIcon,
  MembersIcon,
  SharedIcon,
  UserClockIcon,
} from '@atoms/icons';
import { useDashboard } from '@hc/store';
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Loader } from '../../../atoms';
import { membersCard_Style } from './style';

export function MembersCard(props) {
  const { className = '', ...rest } = props;

  // Store Data
  const { dashboardState, getMembersDownload, memberLoading, loading } =
    useDashboard((state) => ({
      dashboardState: state.dashboardState,
      getMembersDownload: state.getMembersDownload,
      memberLoading: state.memberLoading,
      loading: state.loading,
    }));

  // const { download_url } = dashboardState;

  // getMembertableData
  const membersCard = [
    {
      icon: <SharedIcon />,
      membersCount: dashboardState?.getMembertableData?.UsersInvited ?? 0,
      memberInvitesText: 'Members Invited',
      divider: (
        <Divider orientation="vertical" sx={membersCard_Style.dividerSx} />
      ),
    },
    {
      icon: <MembersIcon />,
      membersCount: dashboardState?.getMembertableData?.MembersJoined ?? 0,
      memberInvitesText: 'Members Joined',
      divider: (
        <Divider orientation="vertical" sx={membersCard_Style.dividerSx} />
      ),
    },
    {
      icon: <UserClockIcon />,
      membersCount: dashboardState?.getMembertableData?.UsersYetToJoin ?? 0,
      memberInvitesText: 'Members Yet to Join',
    },
  ];

  // useEffect(() => {
  //   getMembersDownload();
  // }, []);
  const downloadURI = (uri) => {
    const link = document.createElement('a');
    // link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
  };

  const downloadButtonClick = async () => {
    const response = await getMembersDownload();
    downloadURI(response);
  };

  return (
    <Box className={`${className}`} {...rest}>
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={membersCard_Style.skeletonRoot}
          width="100%"
          height={130}
        />
      ) : (
        <Box sx={membersCard_Style.cardSx}>
          <Box sx={membersCard_Style.membersHeadingSx}>
            <Box sx={membersCard_Style.boxSx}>
              <Typography sx={membersCard_Style.membersTextSx}>
                Members &nbsp;
              </Typography>
              <Typography sx={membersCard_Style.invitesLeftCountSx}>
                {dashboardState?.getMembertableData?.membersLeft ?? 0}
                <span style={membersCard_Style.invitesLeftTextSx}>
                  &nbsp;invites left
                </span>
              </Typography>
            </Box>

            <Box>
              {memberLoading ? (
                <Loader size={17} rootStyle={{ p: 0 }} />
              ) : (
                //  TODO: want to add in feature *
                <a style={{ textDecoration: 'none', cursor: 'pointer' }}>
                  <DownloadsIcon onClick={() => downloadButtonClick()} />
                </a>
              )}
            </Box>
          </Box>
          <Box sx={membersCard_Style.membersWrapperSx}>
            {membersCard.map((eachStat, index) => (
              <>
                <Box key={index} sx={membersCard_Style.iconsWithTextSx}>
                  {eachStat?.icon}
                  <Box>
                    <Typography sx={membersCard_Style.numberSx}>
                      {eachStat?.membersCount}
                    </Typography>
                    <Typography sx={membersCard_Style.membersJoinedSx}>
                      {eachStat?.memberInvitesText}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mr: { xs: 2, md: 1 } }}>{eachStat?.divider}</Box>
              </>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

MembersCard.propTypes = {
  className: PropTypes.string,
};
