export const organizationlayout_style = {
  rootSx: {
    maxHeight: '100vh',
  },

  childrenSx: {
    backgroundColor: 'grey.100',
  },

  bodySx: {
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: '230px 1fr',
    height: '90vh',
    mt: 7.5,
  },

  responsiveBoxSx: {
    display: 'none',
  },

  boxSx: {
    display: 'block',
    backgroundColor: '#fff',
  },
  anchorStyleSx: {
    horizontal: 'right',
  },
  menuSx: {
    '& .MuiPaper-root': {
      padding: '8px 24px 8px 16px',
      boxShadow: '0px 0px 4px 2px #E3E3E3',
    },
  },
  menuItemSx: {
    mx: 2,
    px: 3,
    py: 1,
    fontSize: '14px',
    fontWeight: 500,
  },
};
