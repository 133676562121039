/* eslint-disable camelcase */
import OrganizationSignUp from '@assets/organizationSignUp.svg';

export const forgotPassword_Style = {
  rootSx: {
    backgroundImage: `url(${OrganizationSignUp})`,
    height: '100vh',
    width: '100%',
  },
  centerCardSx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};
