/* eslint-disable camelcase */
import { useContract } from '@hc/store/index';
import { ContractToggleButton } from '@hc/ui/components/organization';
import { ContractTable } from '@hc/ui/components/organization/tables/contractTable';
import { queryClient } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { ContractsHistory_style } from './style';

export default function Contracts(props) {
  const { className = '', ...rest } = props;

  // Store Data
  const { getAllContractDetails, contractCount } = useContract(
    (state) => ({
      contractCount: state.contractCount,
      getAllContractDetails: state.getAllContractDetails,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['getAllContract'],
    });
    getAllContractDetails();
  }, []);

  return (
    <Box className={`${className}`} {...rest} px={3} py={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={ContractsHistory_style.headerSx}>
            <Typography sx={ContractsHistory_style.titleSx}>
              {`Contract (${contractCount})`}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Box sx={ContractsHistory_style.toggleSx}>
                <ContractToggleButton />
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 1 }}>
            <ContractTable />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

Contracts.propTypes = {
  className: PropTypes.string,
};
