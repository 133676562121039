/* eslint-disable jsx-a11y/iframe-has-title */
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useEventListener } from 'ahooks';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function Embed(props) {
  const { src = '', width = '', height = '', ...rest } = props;

  const [loading, setLoading] = useState(true);

  const ref = useRef(null);

  useEventListener(
    'load',
    () => {
      setLoading(false);
    },
    { target: ref }
  );

  return (
    <Box>
      {loading && (
        <Box sx={{ height: '80vh', display: 'grid', placeItems: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <iframe
        ref={ref}
        className="airtable-embed"
        id="iframe-embed"
        src={src}
        width={width}
        height={height}
        style={{
          background: 'transparent',
          border: '0px',
          display: loading ? 'none' : 'block',
        }}
        {...rest}
      />
    </Box>
  );
}

export { Embed };

Embed.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
