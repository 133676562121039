/* eslint-disable import/order */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { organizationRoutes } from '@hc/routes';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { localStorageKeys } from '@hc/utils/constants';
import { routeTo } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import create from 'zustand';
import { useRouting } from '../common/routing';

export const useOrganizationOnboarding = create((set, get) => ({
  user: {
    email: '',
    password: '',
    confirmPassword: '',
    error: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  },
  otpVerified: false,
  loading: false,
  error: null,
  setUser: (payload) => set({ user: payload }),

  // SignUp
  signUp: async (payload) => {
    try {
      set({ loading: true });
      // Hitting the signup API

      const response = await queryClient.fetchQuery({
        queryKey: ['signup'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.auth_url}/user/member/signup`,
            {
              email: payload?.email ?? '',
              password: payload?.password ?? '',
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      // If the user is new
      if (response?.data?.status?.code === 200) {
        toast.success(response?.data?.status?.message);
        routeTo(useRouting, organizationRoutes.login);
        return set((state) => ({
          user: {
            ...state?.user,
          },
          loading: false,
        }));
      }
      if (
        response?.data?.status?.code === '403' &&
        response?.data?.status?.message
      ) {
        // If the user is already exists
        set({
          user: {
            ...payload,
          },
          loading: false,
        });
        toast(response?.data?.status?.message, { icon: '⚠️' });
        return routeTo(useRouting, organizationRoutes.login);
        // return useRouting.setState({ route: appRoutes.login });
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  // SignIn
  signIn: async (payload) => {
    try {
      set({ loading: true });
      // Hitting the signin API

      const response = await queryClient.fetchQuery({
        queryKey: ['login'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.auth_url}/organization-login`,
            {
              email: payload?.email ?? '',
              password: payload?.password ?? '',
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      // If the user is exists
      if (response?.status === 200 && response?.data?.data?.token) {
        const token = response?.data?.data?.token;

        localStorage.setItem(localStorageKeys.authToken, token);
        toast.success('Signed in successfully');

        routeTo(useRouting, organizationRoutes.dashboard);
        return response;
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      set({
        user: payload,
      });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  // forgotPassword
  forgotPassword: async (payload) => {
    try {
      set({ loading: true });

      const response = await queryClient.fetchQuery({
        queryKey: ['getResetPasswordLink'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.auth_url}/getResetPasswordLink`,
            {
              email: payload?.email ?? '',
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (
        response?.data?.status?.code === '401' &&
        response?.data?.status?.message
      ) {
        set({ user: payload, loading: false });
        return toast(response?.data?.status?.message, { icon: '⚠️' });
      }
      if (response?.data?.data?.token) {
        set({
          user: { ...payload },
          loading: false,
        });
        toast.success(response?.data?.status?.message);
      }
      return set({ loading: false });
    } catch (err) {
      set({ user: payload, loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  // resetPassword
  resetPassword: async (payload) => {
    try {
      set({ loading: true });

      const response = await queryClient.fetchQuery({
        queryKey: ['signup', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.auth_url}/organization/change-password`,
            {
              email: payload?.email ?? '',
              password: payload?.password ?? '',
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (response?.data?.status?.code === 200) {
        set({ user: payload, loading: false });
        toast.success(response?.data?.status?.message, {
          duration: 1000,
        });
        setTimeout(() => {
          toast.success('Please sign in to continue...');
        }, 1500);
        localStorage.clear();
        return routeTo(useRouting, organizationRoutes.login);
      }
      set({ user: payload, loading: false });
      return toast.error(response?.data?.status?.message);
    } catch (err) {
      set({ user: payload, loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  verifyOnboardUrl: async () => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['verifyOnboardUrl'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.auth_url}/invite-link-expired-check`,
            {},
            true
          );
          return data;
        },
        staleTime: 30000,
      });
      set({ loading: false });
      if (data?.isExpired === true) {
        toast.success(
          `It looks like you've already signed up for Health Circles...`
        );
        localStorage.clear();
        routeTo(useRouting, organizationRoutes.login);
      }
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  // updateOnboarding
  updateOnboarding: (key, value) => {
    const { user } = get();
    set({
      user: {
        ...user,
        [key]: value,
        error: {
          ...user.error,
          [key]: '',
        },
      },
    });
  },

  // updateErrorOnboarding
  updateErrorOnboarding: (error) => {
    const { user } = get();
    set({
      user: {
        ...user,
        error: {
          ...user.error,
          error,
        },
      },
    });
  },

  // updateState
  updateStateOnBoarding: () =>
    set((state) => ({
      user: {
        ...state?.user,
        email: '',
        password: '',
      },
      loading: false,
    })),
}));
