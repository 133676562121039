export const upload_mapping_style = {
  rootSx: {},
  boxRootSx: {
    border: '1px solid',
    borderColor: '#DBDBDB',
    borderRadius: '8px',
  },
  mapLabelSx: {
    mt: 0.4,
    fontSize: 14,
    color: '#000',
  },
  errorLabelSx: {
    fontSize: 14,
    color: '#EE4B2B',
    px: 1.5,
    pb: 1.5,
  },
};
