/* eslint-disable camelcase */
import { ForgotPasswordContentCard } from '@hc/ui/components';
import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types'
import { forgotPassword_Style } from './style';

export default function ForgotPassword(props) {
  const { className = '', ...rest } = props;

  return (
    <Box className={`${className}`} {...rest} sx={forgotPassword_Style.rootSx}>
      <Box sx={forgotPassword_Style.centerCardSx}>
        <ForgotPasswordContentCard />
      </Box>
    </Box>
  );
}

ForgotPassword.propTypes={
  className:PropTypes.string,
}

