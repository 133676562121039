export const insightsCard_style = {
  insightsTextSx: {
    fontSize: '16px',
    color: 'text.primary',
    fontWeight: 500,
    p: 1,
    ml: 2,
  },
  cardSx: {
    boxShadow: '0px 4px 16px #0000000A',
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    paddingBottom: '16px',
    bgcolor:"#fff"
  },
  skeletonRoot: {
    borderRadius: 2,
    backgroundColor: 'primaryTints.300',
    '&::after': {
      background:
        'linear-gradient( 90deg,transparent,rgba(255,255,255, 0.3),transparent)',
    },
  },
  insightsWrapperSx: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    rowGap: 2,
    columnGap: 7,
    py: 2,
    px: 2,
  },
  membersTextSx: {
    fontSize: '12px',
    color: 'text.hint',
  },
  numberSx: {
    fontSize: '20px',
    color: 'text.primary',
    fontWeight: 600,
  },
  iconsWithTextSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
  },
};
