import {
  AverageMembersIcon,
  FamilyMembersIcon,
  IndividualMembersIcon,
  InvitiesMembersIcon,
} from '@atoms/icons';
import { useDashboard } from '@hc/store';
import { Box, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { insightsCard_style } from './style';

export function InsightsCard(props) {
  const { className = '', ...rest } = props;
  // Store Data
  const { dashboardState, loading } = useDashboard((state) => ({
    dashboardState: state.dashboardState,
    loading: state.loading,
  }));

  // getInsightsData
  const insightsCard = [
    {
      icon: <InvitiesMembersIcon />,
      memberInvitesText: 'Member Invites Left',
      membersCount: dashboardState?.getMembertableData?.membersLeft ?? 0,
    },
    {
      icon: <AverageMembersIcon />,
      memberInvitesText: 'Average Consults per Member',
      membersCount:
        dashboardState?.getInsightsData?.averageConsultsPerMember ?? 0,
    },
    {
      icon: <FamilyMembersIcon />,
      memberInvitesText: 'Members along with family',
      membersCount: dashboardState?.getInsightsData?.memberAlongWithFamily ?? 0,
    },
    {
      icon: <IndividualMembersIcon />,
      memberInvitesText: 'Individual Members',
      membersCount: dashboardState?.getInsightsData?.individualMember ?? 0,
    },
  ];

  return (
    <Box className={`${className}`} {...rest}>
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={insightsCard_style.skeletonRoot}
          width="100%"
          height={200}
        />
      ) : (
        <Box sx={insightsCard_style.cardSx}>
          <Typography sx={insightsCard_style.insightsTextSx}>
            Insights
          </Typography>
          <Box sx={insightsCard_style.insightsWrapperSx}>
            {insightsCard.map((eachStat, index) => (
              <Box key={[index]} sx={insightsCard_style.iconsWithTextSx}>
                {eachStat?.icon}
                <Box>
                  <Typography sx={insightsCard_style.membersTextSx}>
                    {eachStat?.memberInvitesText}
                  </Typography>
                  <Typography sx={insightsCard_style.numberSx}>
                    {eachStat?.membersCount}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
InsightsCard.propTypes = {
  data: PropTypes.array,
  classes: PropTypes.object,
  sx: PropTypes.object,
  className: PropTypes.string,
};
