/* eslint-disable jsx-a11y/iframe-has-title */
import { AppBar, HealthCirclesLogo, SideBar } from '@atoms';
import { ProfileCard } from '@components';
import { organizationRoutes } from '@hc/routes';
import { localStorageKeys } from '@hc/utils';
import { Box, Dialog, Popover, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Embed } from '../../embedIframe';
import { organizationlayout_style } from './style';
import { LogoutModal } from '../../backoffice/logoutModal';

export function Layout(props) {
  const {
    adminName = '',
    role = '',
    navItems = [],
    profiledata = [],
    childrenStyle,
    bodyStyle,
    className = '',
    rootStyle,
    children,
    appBarStyle,
    profileTitle = '',
    Organizationlogo,
    logo = '',
    ...rest
  } = props;
  //   const navigate = useNavigate();
  const navigate = useNavigate();
  const theme = useTheme();

  const viewport = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [profileModel, setProfileModel] = useState(false);
  const [bugModel, setBugModel] = useState(false);
  const [feedback, openFeedback] = useState(false);
  const [dropDownCng, setDropDowncng] = useState(false);
  const [openModel, setModel] = useState(false);
  const userDetail = { userName: adminName, userRole: role };

  const logout = () => {
    setModel(true);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate(organizationRoutes.login);
  };

  const handleCancleModel = () => {
    setModel(false);
  };

  // const feedbackModal = () => {
  //   setDropDowncng(false);
  //   openFeedback(true);
  // };

  // const bugReport = () => {
  //   setDropDowncng(false);
  //   setBugModel(true);
  // };

  // const accountDetail = () => {
  //   setDropDowncng(false);
  //   alert('Not yet integrate MyAccount');
  // };

  const profileModal = () => {
    setDropDowncng(false);
    setProfileModel(true);
  };

  const menuItems = [
    {
      name: 'Profile',
      onclick: profileModal,
    },

    // {
    //   name: 'Report Bugs',
    //   onclick: bugReport,
    // },
    // {
    //   name: 'Feedback',
    //   onclick: feedbackModal,
    // },
    {
      name: 'Logout',
      onclick: logout,
    },
  ];

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const profileModelCloseFnc = () => {
    setProfileModel(false);
  };

  const bugModelCloseFnc = () => {
    setBugModel(false);
  };

  const feedbackCloseFnc = () => {
    openFeedback(false);
  };

  const openProfile = Boolean(profileModel);

  const openBug = Boolean(bugModel);

  return (
    <Box
      sx={{ ...organizationlayout_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ ...organizationlayout_style.appBarSx, ...appBarStyle }}>
        <AppBar
          navItems={navItems}
          openDrawer={openDrawer}
          onOpen={handleDrawerOpen}
          onClose={handleDrawerClose}
          menuItems={menuItems}
          dropDownCng={dropDownCng}
          profiledata={profiledata}
          Organizationlogo={<HealthCirclesLogo />}
          organizationName="HealthCircles"
          logo={logo}
          divider
          userDetail={userDetail}
          anchorOriginStyle={organizationlayout_style.anchorStyleSx}
          menuStyle={organizationlayout_style.menuSx}
          menuItemStyle={organizationlayout_style.menuItemSx}
          version={
            localStorage.getItem(localStorageKeys.organizationVersion) ??
            '0.0.0'
          }
        />
      </Box>

      <Box
        sx={{
          ...(viewport ? ' ' : organizationlayout_style.bodySx),
          ...bodyStyle,
        }}
      >
        <Box
          sx={
            viewport
              ? organizationlayout_style.responsiveBoxSx
              : organizationlayout_style.boxSx
          }
        >
          <SideBar navItems={navItems} />
        </Box>

        {/* Children */}
        <Box
          sx={{
            ...organizationlayout_style.childrenSx,
            ...childrenStyle,
          }}
          onClick={handleDrawerClose}
        >
          <Outlet />
        </Box>
      </Box>
      {profileModel && (
        <Popover
          open={openProfile}
          BackdropProps={{ invisible: false }}
          anchorEl={profileModel}
          onClose={() => profileModelCloseFnc()}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          sx={{ '& .MuiPaper-root': { boxShadow: '0px 0px 4px 2px #E3E3E3' } }}
        >
          <ProfileCard
            logo={logo}
            profileTitle={profileTitle}
            data={profiledata}
            onRequestClose={() => profileModelCloseFnc()}
          />
        </Popover>
      )}

      {bugModel && (
        <Dialog
          open={openBug}
          onClose={bugModelCloseFnc}
          PaperProps={{
            sx: {
              minHeight: 500,
              minWidth: 500,
            },
          }}
        >
          <Embed
            src="https://airtable.com/embed/shrASVhioxy8M7Hgi?backgroundColor=gray"
            width="500"
            height="500"
          />
        </Dialog>
      )}

      {feedback && (
        <Dialog
          open={feedback}
          onClose={feedbackCloseFnc}
          PaperProps={{
            sx: {
              minHeight: 500,
              minWidth: 500,
            },
          }}
        >
          <Embed
            src="https://airtable.com/embed/shrh57Tggcd2rSZOU?backgroundColor=orange"
            width="500"
            height="500"
          />
        </Dialog>
      )}

      {openModel && (
        <LogoutModal
          isModalOpen={openModel}
          handleClose={handleCancleModel}
          handleDelete={handleLogout}
        />
      )}
    </Box>
  );
}

Layout.propTypes = {
  adminName: PropTypes.string,
  navItems: PropTypes.array,
  role: PropTypes.string,
  profiledata: PropTypes.array,
  childrenStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  appBarStyle: PropTypes.object,
  profileTitle: PropTypes.string,
  Organizationlogo: PropTypes.object,
  logo: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.object,
  sx: PropTypes.object,
};
