export const forgotPasswordContentCard_style = {
  CardcontentSx: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 69px #0000001A',
    borderRadius: '12px',
    px: 4,
    py: 5,
    mt: 2.5,
    width: '435px',
  },
  logowithtextSx: {
    display: 'flex',
    alignItems: 'center',
  },
  companytypoSx: {
    color: '#007965',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 600,
  },
  createPasswordSx: {
    fontSize: '20px',
    color: 'text.primary',
    textAlign: 'left',
    fontWeight: 600,
    pt: 1.5,
    pb: 1,
  },
  subTextSx: {
    fontSize: '14px',
    color: 'text.hint',
    width: '335px',
    fontWeight: 'normal',
    pb: 3,
  },
  hcLogoSx: {
    ml: -1.15,
  },
  passwordTextSx: {
    fontSize: '14px',
    color: 'text.hint',
    textAlign: 'center',
    fontWeight: 'normal',
    pt: 3,
    pb: 1.5,
    cursor: 'pointer',
  },
  inputGroupSx: {
    pt: 1.5,
  },
  buttonStyleSx: {
    textTransform: 'capitalize',
  },
};
