import { Table } from '@atoms';
import { useMember } from '@hc/store';

export function UploadDialogTable() {
  const {
    excelData,
    excelColumnData,
  } = useMember((state) => ({
    excelData: state.excelData,
    excelColumnData: state.excelColumnData,  
  }));
  return (
    <Table sx={{ minWidth: 950 }} columns={excelColumnData} rows={excelData} />
  );
}
