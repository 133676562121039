/* eslint-disable no-else-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-sparse-arrays */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import {
  csvToJson,
  GetColumnData,
  GetDropDownData,
  xlsxToJson
} from '@hc/utils/helperFunctions';
import toast from 'react-hot-toast';
import { create } from 'zustand';

const memberInitialData = {
  name: '',
  email_id: '',
  mobile_no: '',
  country_code: '',
  // contract_id: '',
  gender: {},
  error: {
    name: '',
    email_id: '',
    mobile_no: '',
    country_code: '',
    // contract_id: '',
    gender: '',
  },
};

export const useMember = create((set, get) => ({
  member: JSON.parse(JSON.stringify(memberInitialData)),
  excelMappingMemberList: [],
  membersData: [],
  excelData: [],
  excelColumnData: [],
  dropDownData: [],
  download_url: '',
  options: {
    search: '',
    offset: 0,
    limit: 10,
  },
  memberCount: '',
  // contract_Count: {},
  // free_counsulation_count: '',
  // remainingQuesCount: '',
  loading: null,
  addMemberLoading: false,
  error: null,

  getAllOrganizationMembers: async () => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      const { options } = get();
      set({ loading: true });

      if (!tokenData?.organization_id) {
        return;
      }

      const data = await queryClient.fetchQuery({
        queryKey: ['organization_members/all'],
        queryFn: async () => {
          const data = await httpRequest(
            'get',
            `${envConfig.api_url}/organization/members/getAll?organization_id=${
              tokenData?.organization_id ?? ''
            }&search=${options?.search}&offset=${options?.offset}&limit=${
              options?.limit
            }`,
            {},
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      const d = data?.data?.data?.members?.map((tableData) => ({
        id: tableData?.id ?? '',
        fullName: tableData?.first_name ?? '',
        email: tableData?.email_id ?? '',
        membershipCode: tableData?.membership_code ?? '',
        phoneNumber: tableData?.mobile ?? '',
        // gender: tableData?.gender ?? '',
        isActive: tableData?.is_active ?? '',
        status: tableData?.status ?? '',
        statusId: tableData?.status_id ?? '',
        createdAt: tableData?.created_at ?? '',
        updatedAt: tableData?.updated_at ?? '',
        user_profile_id: tableData?.user_profile_id ?? '',
        role_id: tableData?.role_id ?? '',
      }));

      return set({
        loading: false,
        membersData: d,
        download_url: data?.data?.data?.url ?? '',
        memberCount: data?.data?.data?.count,
      });
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.data?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },
  addOrganizationMember: async (payload, val) => {
    // const { free_counsulation_count } = get();

    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ addMemberLoading: true });
      const data = {
        id: payload?.id,
        name: payload?.name ?? '',
        organization_id: tokenData?.organization_id ?? '',
        gender: payload?.gender?.label ?? '',
        mobile_no: payload?.mobile_no ?? '',
        mobile_code: payload?.country_code ?? '',
        email: payload?.email_id ?? '',
        // contract_id: payload?.contract_id ?? '',
        // free_counsulation_count,
        role_id: 3,
        status: 4,
      };
      if (val) {
        queryClient.invalidateQueries({
          queryKey: ['members/add'],
        });
      }

      const response = await queryClient.fetchQuery({
        queryKey: ['members/add', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.auth_url}/user/organization/members/add`,
            {
              ...data,
            },
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });

      queryClient.invalidateQueries({
        queryKey: ['organization_members/all', ''],
      });
      queryClient.invalidateQueries({
        queryKey: ['dashboard', ''],
      });

      // dashboard
      if (response?.data?.statusCode === '200') {
        toast.success(
          response?.data?.message ??
            `Your Member has been created successfully.`,
        );
        set({ addMemberLoading: false });
        return response;
      } else if (response?.data?.statusCode === '500') {
        toast.error(
          response?.data?.message ??
            'Member already exists as a part of another organization, Please contact admin',
        );
        set({ addMemberLoading: false });
        return response;
      }
      set({ addMemberLoading: false });
      return response;
    } catch (error) {
      set({ addMemberLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.message ??
          error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  addExcelMappingMemberList: async () => {
    try {
      const name = (val) => {
        let name = '';
        if (val === 'male' || val === 'Male') {
          name = 'Male';
        } else if (val === 'female' || val === 'Female') {
          name = 'Female';
        }
        return name;
      };

      // const { free_counsulation_count } = get();
      // const { remainingQuesCount } = get();
      const { excelMappingMemberList } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      const data = {
        user_id: tokenData?.user_id,
        organization_id: tokenData?.organization_id ?? '',
        upload_members_data: excelMappingMemberList.map((val) => ({
          name: val?.fullName ?? '',
          email: val?.email ?? '',
          mobile_code: '+91',
          mobile_no: val?.phoneNumber.toString() ?? '',
          gender: val?.gender ? name(val?.gender) : '',
          // contract_id: contract ?? '',
          // free_counsulation_count,
          // question_count: remainingQuesCount,
        })),
      };
      set({ loading: true });
      queryClient.invalidateQueries({
        queryKey: ['upload-members'],
      });

      const response = await queryClient.fetchQuery({
        queryKey: ['upload-members', data],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/upload-members`,
            {
              ...data,
            },
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      queryClient.invalidateQueries({
        queryKey: ['organization_members/all', ''],
      });
      if (
        response?.data?.status?.code === 200 &&
        response?.data?.rejected_data?.[0]?.length === 0
      ) {
        toast.success(
          response?.data?.status?.message ??
            `Your Member has been created successfully.`,
        );
        const { getAllOrganizationMembers } = get();

        getAllOrganizationMembers('');
        // invalidate query
        queryClient.invalidateQueries({
          queryKey: ['dashboard', ''],
        });
        set({ loading: false });
        return response?.data;
      } else {
        toast.success(
          response?.data?.status?.message ??
            `Your Member has been created successfully.`,
        );
        set({ loading: false });
        return response?.data;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.data?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  setExcelMappingMemberList: (result) => {
    if (result) {
      // set({ loading: true });
      return set(() => ({
        // loading: false,
        excelMappingMemberList: result,
      }));
    }
  },

  updateAttachDetach: async (row) => {
    try {
      set({ loading: true });

      const payload = {
        id: row?.id,
        is_active: !row?.is_active,
      };

      const res = await queryClient.fetchQuery({
        queryKey: ['updateMember', row],
        queryFn: async () => {
          const res = await httpRequest(
            'post',
            `${envConfig.api_url}/organization/updateMember`,
            {
              ...payload,
            },
            true,
          );
          return res;
        },
        staleTime: Infinity,
      });

      if (parseInt(res.data.statusCode, 10) === 200) {
        toast.success(`Member Attached Successfullyed `);
        return set({ loading: false });
      }
      // toast.success(`Something went wrong, Try Again!`);
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'something went wrong please try again!',
      );
    }
  },
  handleMemberFormChange: (key, value) => {
    const { member } = get();
    // For preventing change to the original state
    const memberCopy = JSON.parse(JSON.stringify(member));

    let payload = memberCopy;
    // if (key === 'contract_id') {
    //   const [freeConsultation_Count] = contract_Count.filter(
    //     (val) => val.id === value,
    //   );
    //   set({
    //     free_counsulation_count:
    //       freeConsultation_Count?.free_consultation_count_per_member ?? 0,
    //     remainingQuesCount: freeConsultation_Count?.question_count ?? '',
    //   });
    // }
    if (key === 'mobileNumber') {
      payload = {
        ...payload,
        mobile_no: value?.mobile,
        country_code: value?.mobileCode,
      };
    } else {
      payload = {
        ...payload,
        [key]: value,
      };
    }

    set({
      member: payload,
    });
  },
  updateOrganizationError: (error) => {
    const { member } = get();
    set({
      member: {
        ...member,
        error,
      },
    });
  },
  clearOrganizationMember: () => {
    set({
      member: JSON.parse(JSON.stringify(memberInitialData)),
    });
  },
  resendInvite: async (row) => {
    try {
      set({ loading: true });
      const resendPayload = {
        user_id: row?.id,
        user_profile_id: row?.user_profile_id,
        name: row?.fullName,
        email: row?.email,
        mobile: row?.phoneNumber,
        role_id: row?.role_id,
      };
      const res = await queryClient.fetchQuery({
        queryKey: ['resent_invite', row],
        queryFn: async () => {
          const res = await httpRequest(
            'post',
            `${envConfig.auth_url_v1}/resent_invite`,
            {
              ...resendPayload,
            },
            true,
          );
          return res;
        },
        staleTime: Infinity,
      });

      if (parseInt(res.data.statusCode, 10) === 200) {
        set({ loading: false });
        toast.success(`Invite Sent Successfully!!!`);
        return set({ loading: false });
      }
      set({ loading: false });
      toast.success(`Something went wrong, Try Again!`);
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.message ?? 'something went wrong please try again!',
      );
    }
  },
  getExcelData: async (fileType, file) => {
    try {
      if (fileType === 'csv') {
        set({ loading: true });
        const data = await csvToJson(file);
        data.forEach((item, i) => {
          item.id = i + 1;
        });
        return set(() => ({
          loading: false,
          excelData: data,
        }));
      }
      if (fileType === 'xlsx') {
        set({ loading: true });
        const data = await xlsxToJson(file);
        data.forEach((item, i) => {
          item.id = i + 1;
        });
        return set(() => ({
          loading: false,
          excelData: data,
        }));
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.message ?? 'something went wrong please try again!',
      );
    }
  },
  getExcelColumnData: (getObject) => {
    try {
      set({ loading: true });
      const data = GetDropDownData(getObject);
      const columnData = GetColumnData(getObject);
      return set(() => ({
        loading: false,
        excelColumnData: columnData,
        dropDownData: data,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.message ?? 'something went wrong please try again!',
      );
    }
  },

  // getMemberCount: async (organization_id) => {
  //   try {
  //     set({ loading: true });
  //     const { data } = await httpRequest(
  //       'get',
  //       `${envConfig.api_url}/contracts/member-count/get?organization_id=${organization_id}`,
  //       {
  //         organization_id,
  //       },
  //       true,
  //     );
  //     if (data.status.code === 200) {
  //       set(() => ({
  //         loading: false,
  //         contract_Count: data.data ?? {},
  //       }));
  //     }
  //   } catch (error) {
  //     set({ loading: false });
  //     log('error', error);
  //     return toast.error(
  //       error?.response?.data?.status?.message ??
  //         error?.message ??
  //         'Something went wrong please try again!',
  //     );
  //   }
  // },
  updateStateData: (key, value) => {
    const { options } = get();
    set({
      options: {
        ...options,
        [key]: value,
      },
    });
  },
}));
