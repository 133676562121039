import { Box, Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { leaderBoardSkeletonLoader_style } from './style';

function LeaderBoardSkeletonLoader(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...leaderBoardSkeletonLoader_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={leaderBoardSkeletonLoader_style.cardRootSx}>
        <Grid container>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3}>
                <Skeleton
                  width={36}
                  height={36}
                  animation="wave"
                  variant="circular"
                  sx={{
                    ...leaderBoardSkeletonLoader_style.skeletonRoot,
                  }}
                />
              </Grid>
              <Grid item xs={9}>
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    <Skeleton
                      width="100%"
                      height={15}
                      animation="wave"
                      variant="rounded"
                      sx={{
                        ...leaderBoardSkeletonLoader_style.skeletonRoot,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton
                      width="100%"
                      height={15}
                      animation="wave"
                      variant="rounded"
                      sx={{
                        ...leaderBoardSkeletonLoader_style.skeletonRoot,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="flex" justifyContent="space-around" xs={6}>
            {Array(3)
              .fill(1)
              .map((i) => (
                <Box key={i}>
                  <Skeleton
                    width={36}
                    height={36}
                    animation="wave"
                    variant="circular"
                    sx={{
                      ...leaderBoardSkeletonLoader_style.skeletonRoot,
                    }}
                  />
                </Box>
              ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

LeaderBoardSkeletonLoader.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { LeaderBoardSkeletonLoader };

