import { useContract } from '@hc/store/index';
import { ToggleButton } from '@hc/ui/atoms';
import { queryClient } from '@hc/utils';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';

export function ContractToggleButton() {
  const {
    getAllContractDetails,
    status,
    updateStatusReport,
    setContractFilter,
  } = useContract(
    (state) => ({
      status: state.status,
      getAllContractDetails: state.getAllContractDetails,
      updateStatusReport: state.updateStatusReport,
      setContractFilter: state.setContractFilter,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const options = [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Expired',
      value: 'Expired',
    },
  ];

  const onStatusChange = (status) => {
    updateStatusReport(status);
    setContractFilter(status);
    queryClient.invalidateQueries({
      queryKey: ['getAllContract'],
    });
    getAllContractDetails();
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['getAllContract'],
    });
    getAllContractDetails();
  }, []);

  return (
    <Box>
      <ToggleButton
        onChange={(status) => onStatusChange(status)}
        options={options}
        value={status || 'All'}
      />
    </Box>
  );
}
