/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
import { AgreementIcon, DashboardIcon, MembersUserIcon } from '@atoms';
import { envConfig } from '@hc/config';
import { useOrgProfile, useRouting } from '@hc/store';
import { OrgAddress, OrgCall, OrgEmail } from '@hc/ui/atoms/icons';
import { localStorageKeys } from '@hc/utils/constants';
import { lazyLoadScript } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Layout } from '../layout';

function RootLayout(props) {
  const {
    className = '',
    showLayout = true,
    children,
    backgroundStyle,
    rootStyle,
    ...rest
  } = props;

  const [searchParams] = useSearchParams();

  const { profileView, getProfileView } = useOrgProfile((state) => ({
    profileView: state.profileView,
    getProfileView: state.getProfileView,
  }));

  const navigate = useNavigate();
  const route = useRouting((state) => state.route);

  lazyLoadScript(
    `https://www.googletagmanager.com/gtag/js?id=${envConfig.google_analytics}`
  )
    .then(() => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', `${envConfig.google_analytics}`);
    })
    .catch((error) => {
      log('error', error);
      console.error('Failed to load Google Analytics:', error);
    });

  const sideNav = [
    {
      icon: <DashboardIcon />,
      navItem: 'Dashboard',
      path: '/',
    },
    {
      icon: <MembersUserIcon />,
      navItem: 'Members',
      path: '/members',
    },
    {
      icon: <AgreementIcon />,
      navItem: 'Contracts',
      path: '/contracts',
    },
  ];
  const profiledata = [
    {
      icon: <OrgEmail />,
      heading: 'Email',
      desc: `${profileView?.email_id}`,
    },
    {
      icon: <OrgCall />,
      heading: 'Phone Number',
      desc: `${profileView?.mobile_no_country_code}  ${profileView?.mobile_no}`,
    },
    {
      icon: <OrgAddress />,
      heading: 'Address',
      desc: `${profileView?.address}`,
    },
  ];

  useEffect(() => {
    showLayout && getProfileView();
  }, []);

  useEffect(() => {
    if (searchParams.get('token')?.length > 0) {
      localStorage.clear();
      localStorage.setItem(
        localStorageKeys.authToken,
        searchParams.get('token')
      );
    }
  }, []);

  useEffect(() => {
    if (route !== null) {
      navigate(route);
      setTimeout(() => {
        useRouting.setState({ route: null });
      }, 1000);
    }
  }, [route]);

  return (
    <Box className={`${className}`} {...rest}>
      <Toaster />
      {!showLayout && children}
      {showLayout && (
        <Box>
          <Layout
            adminName={profileView?.organization_name}
            role="Admin"
            profiledata={profiledata}
            profileTitle={profileView?.organization_name}
            logo={profileView?.logo?.downloadUrl}
            navItems={sideNav}
          />
        </Box>
      )}
    </Box>
  );
}

export { RootLayout };

Layout.propTypes = {
  className: PropTypes.string,
  showLayout: PropTypes.object,
  children: PropTypes.object,
  backgroundStyle: PropTypes.object,
  rootStyle: PropTypes.object,
};
