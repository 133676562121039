export const upload_dialog_style = {
  titleSx: {
    fontSize: 20,
  },
  tableContainerSx: {
    border: '1px solid',
    borderColor: '#DBDBDB',
    borderRadius: '8px',
  },
  tableCellSx: {
    fontSize: '14px',
    color: 'text.secondary',
  },
  tableHeaderCellSx: {
    fontSize: '16px',
  },
  buttonSx: {
    textTransform: 'none',
  },
  dialogSx: {
    width: '900px',
  },
};
